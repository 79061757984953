.coupon-com {
    width: 881px;
    height: 1034px;
    border-radius: 48px;
    .content {
        text-align: center;
        width: 100%;
        height: 922px;
        background-color: #ffdc33;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .coupon-container {
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }
        .text {
            height: 72px;
            padding: 48px 0 0 0;
            font-size: 40px;
            font-weight: 600;
        }
        .conpon {
            display: flex;
            flex-direction: row;
            text-align: left;
            margin: 48px 48px 0 72px;
            align-items: center;
            height: 72px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .icon {
                // width: 72px;
                // height: 72px;
            }
            .text-coupon {
                font-size: 32px;
                font-weight: 400;
                margin-left: 24px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .tail-bar {
        display: flex;
        width: 100%;
        height: 112px;
        background-color: #f0f0f0;
        border-bottom-left-radius: 72px;
        border-bottom-right-radius: 72px;
        justify-content: center;
        align-items: center;
        .text {
            color: #9aa3b0;
            font-size: 32px;
        }
    }
}

.temporary-closed-com {
    width: 881px;
    height: 1034px;
    background-color: #fff;
    border-radius: 48px;
    .content {
        text-align: center;
        width: 100%;
        height: 922px;
        background-color: #ffdc33;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px;
        .text {
            color: black;
            font-size: 40px;
            font-weight: 600;
            padding: 48px;
            height: 72px;
        }
        .img {
            width: 535px;
            height: 489px;
        }
        .in {
            color: black;
            font-size: 40px;
            font-weight: 600;
            padding-top: 48px;
            height: 72px;
        }
        .time {
            color: #fd5d16;
            font-size: 60px;
            font-weight: 600;
            height: 72px;
        }
    }
    .tail-bar {
        display: flex;
        width: 100%;
        height: 112px;
        background-color: #f0f0f0;
        border-bottom-left-radius: 72px;
        border-bottom-right-radius: 72px;
        justify-content: center;
        align-items: center;
        .text {
            color: #9aa3b0;
            font-size: 32px;
        }
    }
}
.dialog-com {
    .MuiPaper-root {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0);
    }
    .swipeable-page {
        width: 881px;
        height: 1034px;
        background-color: rgba(255, 255, 255, 0);
    }

    .Stepperdots {
        width: 880px;
        padding: 20px 0;
    }

    .close-btn {
        position: absolute;
        top: 40px;
        right: 30px;
        .btn {
            background-color: #9f9e98;
            border-radius: 88px;
            width: 60px;
            height: 60px;
            color: #fff;
            padding: 12px;
            cursor: pointer;
        }
    }
}
