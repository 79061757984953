.top-bar {
    display: flex;
    width: 100%;
    height: 196px;
    justify-content: space-between;
    align-items: center;
    background: #ffdc33;
    .bar-left-icon {
        margin-left: 48px;
    }
    .title-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 70%;
        font-family: "Krub SemiBold", "Krub Regular", "Krub";
        font-weight: 600;
        font-style: normal;
        font-size: 55px;
        color: #000000;
        .info {
            font-family: "Arial Normal", "Arial";
            font-size: 40px;
            color: #a57e50;
            font-weight: 400;
            padding-top: 10px;
        }
        .closed {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: #f4cc17;
            border-radius: 88px;
            height: 64px;
            margin-top: 10px;
            padding: 0 12px;
            .text {
                font-size: 36px;
                color: #aa5d2a;
                padding: 0 12px;
            }
        }
    }
    .bar-menu {
        margin-right: 48px;
    }
    .tool-icon {
        width: 72px;
        height: 72px;
    }
    .confrimicon {
        .bar-menu {
            margin-right: 0;
        }
    }
    .basket {
        .bar-menu {
            margin-right: 0;
        }
        .MuiButton-label {
            display: flex;
            flex-direction: column;
            color: #000000;
            font-size: 30px;
            font-weight: bold;
            font-family: "Krub";
        }
    }
}

.is-no-bg {
    background: none;
}
