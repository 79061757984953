.order-page-menu-list {
    flex: 1;
    padding: 24px;
    outline: 0;
    .menu-title-text {
        font-size: 40px;
        text-align: left;
        margin-left: 24px;
        padding: 32px 0;
        color: #989eb6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
