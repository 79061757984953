.businessBox {
    background-color: #36353c;
    flex: 1;
    margin-right: 24px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 50px;
    color: #c09c72;
    padding: 24px 0;
    box-sizing: border-box;
    font-family: "Krub";
    .iconBox {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #b07c4d;
        width: 120px;
        height: 120px;
        border-radius: 60px;
        margin-bottom: 24px;
        svg {
            font-size: 60px;
        }
    }
    .title {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 38px;
    }
}
.noMargin {
    margin: 0;
}
