.set-dish-item-dialog-bg {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #00000059;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
    color: #000;
    .title-bar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f8f8f8;
        z-index: 2;
        text-align: left;
        transition: all 0.2s;
        padding: 48px 45px;
        .title-text {
            font-size: 50px;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 100px;
            white-space: nowrap;
        }
        .title-bar-close-icon {
            position: absolute;
            right: 15px;
            width: 28px;
            height: 28px;
            top: 8px;
            color: #ffdc33;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            svg {
                width: 90%;
                height: 90%;
            }
        }
    }
    .close-icon {
        position: absolute;
        right: 45px;
        top: 45px;
        width: 70px;
        height: 70px;
        background: #4a4a4a87;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        z-index: 2;
        transition: all 0.2s;

        svg {
            width: 80%;
            height: 80%;
        }
    }
    .set-dish-item-dialog {
        width: 100%;
        height: 100%;
        background: #f8f8f8;
        // border-radius: 10px;
        position: relative;
        text-align: left;
        .set-dish-item-dialog-content {
            border-radius: 10px;
            overflow: scroll;
            height: 100%;
            padding-bottom: 58px;
            .dish-img {
                height: 675px;
                padding-bottom: 8px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: #f5f5f5;
                width: 100%;
                margin-bottom: -50px;
                .setMenu-icon {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    background: #ffdc33;
                    border-radius: 25px;
                    padding: 4px 14px;
                    color: #fff;
                    font-size: 14px;
                }
            }
            .add-dish-btn {
                position: absolute;
                bottom: 45px;
                left: 70px;
                right: 70px;
                display: flex;
                z-index: 2;
                .add-dish-btn-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                    padding: 30px 45px;
                    border-radius: 100px;
                    background: #ffdc33;
                    box-shadow: 0px 5px 10px 0px #87724080;
                    .order-cart-icon-box {
                        display: flex;
                        align-items: center;
                        .md-cart-icon {
                            height: 60px;
                            width: 60px;
                            margin-right: 20px;
                        }
                        .order-cart-icon-box-text {
                            margin-left: 8px;
                            font-size: 40px;
                            font-weight: bold;
                        }
                        .beask-total-num-text {
                            font-size: 40px;
                            margin-left: 8px;
                            color: #8688a4;
                        }
                    }
                    .price-text {
                        font-size: 40px;
                        font-weight: bold;
                    }
                }
                .add-dish-btn-disabled {
                    background: #bfc7cf;
                }
            }
            .set-dish-content {
                border-top-left-radius: 70px;
                border-top-right-radius: 70px;
                padding: 32px 32px 0;
                background-color: #f8f8f8;
            }
            .set-dish-name {
                margin: 0 0 16px;
                font-weight: bold;
                font-size: 50px;
                // position: sticky;
                // box-shadow: 0 -1px #ffffff;
                // top: 0px;
                // z-index: 1;
            }
            .set-dish-name-ext {
                margin: 0 0 16px;
                font-size: 45px;
                color: #92949c;
            }
            .set-dish-price-text {
                color: #edb700;
                font-size: 50px;
                margin: 0 0 16px;
            }
            .choose-a-product-text {
                margin: 4px 0 32px;
                font-size: 35px;
                color: #b8b8b8;
                border-top: 1px solid #e3e3e3cc;
                text-align: center;
                padding-top: 10px;
            }
            .course-group-list {
                padding-bottom: 200px;
                .quantity-info {
                    display: flex;
                    align-items: center;
                    font-size: 40px;
                    margin-bottom: 32px;
                    color: #8688a4;
                    margin-top: 8px;
                    .check-icon {
                        color: #43b01d;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 4px;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .required-text {
                        margin-right: 8px;
                    }
                    .min-num-dishes-text {
                        margin-right: 8px;
                    }
                    .select-num-text {
                        display: inline-flex;
                        align-items: center;
                        background-color: #ff8873;
                        border-radius: 16px;
                        padding: 16px;
                        color: #fff;
                        margin-right: 8px;
                    }
                    .area-additional-fees-text {
                        padding: 16px;
                        background-color: #ff8873;
                        color: #ffffff;
                        border-radius: 16px;
                    }
                }
                .quantity-info-fail {
                    display: inline-flex;
                    align-items: center;
                    background-color: #ff8873;
                    border-radius: 16px;
                    color: #fff;
                    padding: 16px;
                    margin-right: 8px;
                    font-size: 40px;
                    .error-icon {
                        width: 45px;
                        height: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 8px;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .set-dish-item-course-card {
                    .name-and-qiantity-box {
                        margin: 24px 0;
                        .course-group-name {
                            font-size: 40px;
                            margin-bottom: 32px;
                        }
                    }
                    // 菜品列表
                    .course-dish-list {
                        // 菜品卡片
                        .content {
                            display: flex;
                            justify-content: space-between;
                            flex: 1;
                            overflow: hidden;
                            margin-bottom: 24px;
                            box-shadow: 0px 10px 15px 0px #e3e7f4;
                            border-radius: 25px;
                            padding: 24px 32px;
                            background: #fff;
                            position: relative;
                            .dishImgbox {
                                width: 200px;
                                height: 200px;
                                background: #f5f5f5;
                                border-radius: 40px;
                                position: relative;

                                .dishImg {
                                    min-width: 100%;
                                    max-width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 40px;
                                }
                            }
                            .dish-detail-box {
                                flex: 1;
                                display: flex;
                                justify-content: space-between;
                                flex-direction: column;
                                padding-left: 16px;
                            }
                            .dish-name {
                                font-size: 40px;
                                font-weight: bold;
                                margin-bottom: 24px;
                                color: #333;
                                word-break: break-word;
                            }
                            .dish-description {
                                font-size: 40px;
                                color: #92949c;
                                word-break: break-word;
                            }
                            p {
                                margin: 0;
                            }
                            .center {
                                text-align: left;
                                display: flex;
                                align-items: center;
                            }
                            .pricebox {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .price-box-price {
                                    display: flex;
                                    word-break: break-word;
                                    text-align: left;
                                    align-items: flex-end;
                                    font-weight: 500;
                                    font-size: 40px;
                                    color: #303460;
                                    font-weight: bold;
                                }
                                .price-number {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    transition: all 200ms ease-in-out;
                                }
                                .MuiIconButton-root {
                                    padding: 0;
                                }
                                .number {
                                    margin: 0 20px;
                                    // color: #ff8873;
                                    font-size: 40px;
                                    opacity: 1;
                                    transition: all 200ms ease-in-out;
                                }
                                .is_no_number {
                                    color: #ffdc33;
                                    opacity: 0;
                                    display: none;
                                }
                            }
                            .AiFillPlusCircle {
                                width: 70px;
                                height: 70px;
                                color: #ffdc33;
                                transition: all 200ms ease-in-out;
                            }
                            .AiFillMinusCircle {
                                width: 70px;
                                height: 70px;
                                color: #ffdc33;
                                opacity: 1;
                                transition: all 200ms ease-in-out;
                            }
                            .no-number-circle {
                                color: #e8e7eb;
                                opacity: 0;
                                display: none;
                            }
                            .allergen-pic {
                                width: 58px;
                                height: 58px;
                                margin: 8px 4px 0;
                            }
                            // 禁用加号按钮
                            .disabled-plus-icon {
                                opacity: 1;
                                color: #d0d4d7;
                            }
                        }
                        // 禁用
                        .course-dish-list-dish-disabled {
                            opacity: 0.6;
                            background-color: #ffffff99;
                            .dish-info-box {
                                color: #00000099;
                            }
                            .priceboxprice {
                                color: #00000099;
                            }
                        }
                    }
                }
            }
        }
    }
}
