.settingsPage {
    height: 100vh;
    width: 100vw;
    background: #f8f8f8;
    overflow: hidden;
    header {
        display: flex;
        align-items: center;
        margin: 32px 0 192px;
        .arrowIco {
            width: 88px;
            height: 88px;
            margin: 0 320px 0 71px;
        }
        p {
            font-family: "Krub SemiBold", "Krub Regular", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 55px;
            color: #000000;
        }
    }
    .basic {
        font-family: "Krub Medium", "Krub Regular", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        color: #bcbcbc;
        text-align: left;
        padding-left: 119px;
        line-height: 72px;
    }
    .mainContainer {
        margin: 48px 72px 0;
        background: #fff;
        box-sizing: border-box;
        border-width: 1px solid rgba(255, 255, 255, 1);
        border-radius: 22px;
        -moz-box-shadow: 0px 10px 15px rgba(242, 242, 242, 1);
        -webkit-box-shadow: 0px 10px 15px rgba(242, 242, 242, 1);
        box-shadow: 0px 10px 15px rgba(242, 242, 242, 1);
        padding: 48px;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:nth-of-type(2) {
                border-top: 1px solid #f5f5f5;
                border-bottom: 1px solid #f5f5f5;
                padding: 48px 0;
            }
            p {
                font-family: "Krub Medium", "Krub Regular", "Krub";
                font-weight: 500;
                font-style: normal;
                font-size: 40px;
                color: #9791bf;
                text-align: left;
                height: 72px;
                line-height: 72px;
                &:nth-of-type(2) {
                    color: #bcbcbc;
                }
            }
            .optionContainer {
                position: relative;
                margin-bottom: 46px;
                .arrowBottomIco {
                    position: absolute;
                    top: calc(50% - 24px);
                    right: 40px;
                    width: 48px;
                    height: 48px;
                    color: #525b85;
                }
            }
            .MuiInputBase-root {
                box-sizing: border-box;
                width: 265px;
                height: 144px;
                background: rgba(255, 255, 255, 1);
                box-sizing: border-box;
                border: 1px solid rgba(207, 207, 207, 1);
                border-radius: 88px;
                font-size: 50px;
                padding: 2px 17px;
            }
            .MuiNativeSelect-select {
                box-sizing: border-box;
                background: transparent;
                color: #525b85;
                padding: 0 40px;
                z-index: 1;
                height: 100%;
            }
            .MuiInput-underline:after,
            .MuiInput-underline:before {
                border-bottom: none;
            }
            .MuiNativeSelect-icon {
                display: none;
            }
            .logoutBtn {
                width: 264px;
                height: 144px;
                background: rgba(255, 220, 51, 1);
                border-radius: 88px;
                -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
                -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
                box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
                font-size: 40px;
                text-align: center;
                line-height: 144px;
            }
        }
        .pricaybox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Krub Medium", "Krub Regular", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
            padding-top: 24px; 
            .pricayboxIcon {
                margin-right: 15px;
            }
        }
    }
}
