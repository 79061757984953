.FulfillmentAddress {
    .restaurantAddress {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        background-color: rgba(50, 49, 97, 1);
        border: none;
        border-radius: 40px;
        padding: 48px;
        width: 900px;
        .logo {
            width: 120px;
            height: 120px;
            border-radius: 20px;
            img {
                border-radius: 20px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .content {
            flex: 1;
            margin-left: 48px;
        }
        .name {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #989eb6;
            font-weight: unset !important;
            text-align: left;
            margin-bottom: 24px;
        }
        .address {
            display: flex;
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #ffffff;
            font-weight: unset !important;
            text-align: left;
            line-height: 55px;
        }
    }
    .customerAddressBox {
        width: 100%;
        overflow: hidden;
        text-align: left;
        .customerAddress {
            display: inline-block;
            white-space: nowrap;
            .addressItem {
                margin: 0 24px;
                display: inline-flex;
                white-space: normal;
                &:nth-of-type(1) {
                    margin-left: 0;
                }
                .ruleText {
                    width: 80%;
                }
                .defaultAddressCars {
                    width: 920px;
                }
            }
            .noAddressText {
                display: flex;
                justify-content: flex-start;
                margin-top: 16px;
                .text {
                    font-size: 30px;
                    color: red;
                    margin-left: 20px;
                }
            }
        }
    }
}
