.dishDisabledMask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    background-color: #ffffff80;
    display: flex;
    padding: 24px 32px;
    border-radius: 25px;
    .disabledTagBox {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .MdFilterAlt-icon-box {
        width: 80px;
        height: 80px;
        background-color: #fff;
        color: #ffdc33;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 10px 10px 0px #0000004a;
        margin: 0 8px;
        .MdFilterAlt-icon {
            width: 64px;
            height: 64px;
        }
    }
    .small {
        width: 72px;
        height: 72px;
    }
}

.dishDetail {
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    .disabledTagBox {
        width: unset;
        height: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .MdFilterAlt-icon-box {
        width: 160px;
        height: 160px;
        border-radius: 40px;
        margin: 0 16px;
        .MdFilterAlt-icon {
            width: 128px;
            height: 128px;
        }
    }
}
