
.dialogContent {
    display: flex;
    flex-direction: column;
    /* height: 500px; */
}

.dialogContent .ok {
    width: 354px;
    height: 144px;
    background-color: #ffdc33;
    color: #000;
    border-radius: 88px;
}

.dialogContent .cancel {
    width: 354px;
    height: 144px;
    background-color: #5867b4;
    color: #fff;
    border-radius: 88px;
}