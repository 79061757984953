.restaurantpage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    .notfind {
        width: 100vw;
        height: calc(100% - 196px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-family: Medium;
    }
    .pagebody {
        height: calc(100% - 196px);
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
    }

    .pagebodyinfoHead {
        width: 100%;
        height: 200px;
        margin-top: -200px;
        z-index: 2;
        background: #fff;
        border-top-left-radius: 58px;
        border-top-right-radius: 58px;
    }
    .notHead {
        height: 50px;
        margin-top: -50px;
    }
    .pagebodyinfo {
        display: flex;
        height: 300px;
        padding: 20px 72px;
        z-index: 2;
        border-top-left-radius: 58px;
        border-top-right-radius: 58px;
        .imgbox {
            width: 240px;
            flex-direction: column;
            height: 240px;
        }
        .introductionbox {
            // width: calc(100% - 240px);
            width: calc(100% - 240px - 96px - 48px);
            height: 100%;
            padding: 0 24px;
        }

        .chipbox {
            margin-top: 10px;
            text-align: left;
            overflow-x: scroll;
            // text-overflow: ellipsis;
            white-space: nowrap;
            height: 100px;
            div {
                margin-right: 10px;
                margin-top: 5px;
            }
            .MuiChip-root {
                background-color: #6aaaff;
                color: #fff;
            }
        }
        .bookAndShareBox {
            width: 96px;
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .iconbutton {
            width: 96px;
            height: 96px;
            border-radius: 30px;
            background: #fff;
            color: #ffdc33;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 5px solid #ffdc33;
            .iconItem {
                width: 50%;
                height: 50%;
            }
        }
    }
    .restaurantCouponPackage {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 24px;
        .couponItemBox {
            margin: 0 24px;
            .couponItem {
                height: calc(311px - 48px);
                width: calc(928px - 48px);
            }
        }
        .ruleText {
            width: 80%;
        }
    }
    .restaurant-logo {
        width: 100%;
        border-radius: 50px;
        height: 100%;
        object-fit: cover;
    }

    .retaurantCouponBox {
        position: absolute;
        background-color: transparent;
        .getCuoponBox {
            right: 45px;
            top: 320px;
        }
    }
    .GetCuoponBoxBackground {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background: #00000080;
    }
    .darkTopbar {
        background-color: #282733;
        div {
            color: #fff;
        }
        .title {
            color: #fff;
        }
        .info {
            color: #b07c4d;
        }
    }
}

.scheduleDialog {
    font-family: "kurb";
    .MuiDialog-paperFullWidth {
        border-radius: 70px;
    }
    .head {
        height: 171px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 55px;
        font-weight: bold;
        text-align: center;
    }
    .orderWayBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        .chipItem {
            margin: 0 36px;
            padding: 8px 16px;
            font-size: 40px;
            color: #989eb6;
            background-color: #f2f2f7;
        }
        .activeBg {
            background-color: #ffdc33;
            color: #000;
        }
    }
    .dialogbody {
        text-align: center;
        width: 90%;
        height: calc(100% - 96px - 171px);
        margin: 0 auto 96px;
        overflow: scroll;
        min-height: 500px;
    }
    & ::-webkit-scrollbar {
        display: none;
    }
    .datebox {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-size: 40px;
        padding: 22.5px 0;
    }
    .date {
        width: 100%;
        text-align: left;
        color: #bcbcbc;
    }
    .time {
        width: 580px;
        color: #333;
        font-family: Medium;
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 0 20px;
        }
        span {
            width: 50%;
        }
    }
}
.TipsDialog {
    .dialogbody {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Medium;
        font-size: 50px;
        height: 15.833vw;
    }
}
.closeIcon {
    display: flex;
    height: 15.833vw;
    width: 15.833vw;
    position: absolute;
    right: 0;
    button {
        width: 100%;
    }
}
.Paperbarground {
    margin-bottom: 250px;
    padding: 0 24px;
    position: relative;
    font-size: 40px;
    .paperbox {
        display: flex;
        width: 100%;
        min-height: 171px;
        margin: 20px 0;
        .paperboxbetween {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .icoButton {
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffdc33;
            svg {
                fill: currentColor;
                display: inline-block;
                font-size: 1.5rem;
                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                flex-shrink: 0;
                user-select: none;
            }
        }
        .iconButtonright {
            color: #000000de;
        }
        .contentbox {
            width: calc(100% - 240px);
            text-align: left;
            font-size: 40px;
            p {
                color: #bcbcbc;
                white-space: pre;
                display: flex;
                align-items: center;
                padding: 20px 0 0;
            }
            div,
            a {
                width: 100%;
                display: block;
                color: #333;
                // text-overflow: ellipsis;
                overflow: scroll;
                white-space: nowrap;
                font-family: Medium;
                line-height: 65px;
            }
            .todybox {
                display: flex;
                align-items: center;
                justify-content: center;
                .tody {
                    color: #bcbcbc;
                    padding-right: 10px;
                    width: 160px;
                }
                .time {
                    width: calc(100% - 160px);
                }
                .time div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    white-space: initial;
                }
            }
        }
    }
}
.orderbuttons {
    height: 250px;
    display: flex;
    background-color: transparent;
    justify-content: space-around;
    align-items: center;
    color: #ffdc33;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    font-family: "krub";
    z-index: 2;
    .bubblesBox {
        position: absolute;
        top: 0;
        display: flex;
        width: 100%;
        height: 1px;
        justify-content: center;
        align-items: flex-end;
    }
    .bubbles {
        position: unset;
    }
    .reservationButton {
        margin-right: 24px;
        background: #ffdc33;
        color: #000;
        height: 168px;
        border-radius: 150px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 50px;
        box-shadow: 0px 20px 25px 0px #b3bad6cc;
        min-width: 220px;
        padding: 0 24px;
    }
    .deliveryButton {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        height: 168px;
        font-weight: bold;
    }
    .pickup {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: -5px 15px 25px 0px #b3bad6cc;
        background-image: linear-gradient(to right, #19192a, #24253e, #2b2b49);
        border-right: 1px solid #989eb6a2;
    }
    .delivery {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 5px 15px 25px 0px #b3bad6cc;
        background-image: linear-gradient(to right, #2b2b49, #22223a, #50487d);
    }
    .iconBox {
        font-size: 55px;
    }
    .title {
        font-size: 35px;
        padding-left: 10px;
        font-weight: normal;
        // font-family: Regular;
    }
    .disable {
        color: #6f6f6f !important;
        // opacity: 0.5;
        background: #cecece;
    }
}
.markdown {
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
    font-size: 4.333vw;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
    li {
        list-style: unset;
        display: list-item;
        text-align: -webkit-match-parent;
    }
    em {
        font-style: italic;
        color: #333;
    }
    del {
        text-decoration: line-through;
    }
}
