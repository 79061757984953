.checkoutOrderRemarks {
    .close {
        color: #cecece !important;
        width: 72px !important;
        height: 72px !important;
        margin: 0 8px;
    }
    .check {
        color: #ffdc33 !important;
        width: 72px !important;
        height: 72px !important;
        margin: 0 8px;
    }
    .textareaBox {
        flex: 1;
        textarea {
            width: 100%;
            background-color: #fff;
            border-width: 0;
            min-height: 176px;
            border-radius: 40px;
            padding: 48px;
            box-sizing: border-box;
            font-size: 40px;
            color: #5867b4;
        }
    }
}
