.fulfillmentTime {
    .prompt-busy {
        display: flex;
        justify-content: flex-start;
        margin-top: 16px;
        .text {
            font-size: 30px;
            color: #fd7b88;
            margin-left: 20px;
        }
    }
    .selectTimeBox {
        display: flex;
        align-items: center;
        .selectTimeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 18px 36px;
            border-radius: 80px;
            background-color: #fff;
            &:nth-of-type(2) {
                margin-left: 20px;
            }
            span {
                font-family: "Krub";
                font-weight: 400;
                font-style: normal;
                font-size: 40px;
                color: #8fa4c2;
            }
            .arrow_btn {
                width: 80px;
                height: 80px;
                color: #8fa4c2;
            }
            .showYearText {
                margin-right: 48px;
            }
        }
        .errorBtn {
            border: 1px solid #fd7b88;
        }
    }
}
