.myNotice {
    background-color: #f8f8f8;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .top-bar {
        background-color: #f8f8f8;
    }
    .noData {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        color: #bcbcbc;
    }
    .noticeList {
        flex: 1;
        overflow: auto;
        padding: 0 0 60px;
        .noticeItem {
            background-color: #fff;
            border: 1px solid #e8e8ec;
            border-radius: 60px;
            margin: 20px 48px;
            padding: 48px 30px;
            .top {
                color: #bcbcbc;
                font-size: 30px;
                display: flex;
                justify-content: space-between;
                .left {
                    display: flex;
                    align-items: center;
                    img {
                        width: 48px;
                        height: 48px;
                        margin-right: 30px;
                    }
                }
                .right {
                }
            }
            .bottom {
                color: #333;
                font-size: 40px;
                text-align: left;
                margin: 20px 0;
                line-height: 60px;
            }
        }
    }
}
