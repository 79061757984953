.orderlivery-main * {
    box-sizing: boder-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
}

.loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: linear-gradient(#4e557499, #12122499);
    color: #ffdc33;
}

.loading-hide {
    display: none;
}

.fontStyle {
    font-family: "Krub SemiBold", "Krub Regular", "Krub";
    font-weight: 600;
    font-style: normal;
    text-align: center;
}

.fontStyle-size-400 {
    font-family: "Krub SemiBold", "Krub Regular", "Krub";
    font-weight: 400;
    font-style: normal;
    text-align: center;
    font-size: 40px;
}

.orderlivery-bottom-96 {
    margin-bottom: 96px;
}

.orderlivery-bottom-72 {
    margin-bottom: 72px;
}

.orderlivery-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffdc33;
    flex-direction: column;
}

.orderlivery-top-bar {
    width: 100%;
    margin-top: 48px;
    /* margin-left: 48px;
    margin-right: 48px; */
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderlivery-left-icon {
    margin-left: 48px;
    width: 88px;
    height: 88px;
}

.orderlivery-menu {
    margin-right: 48px;
    width: 88px;
    height: 88px;
}

.title-text {
    color: "#000000";
    font-size: 55px;
    font-weight: bold;
}

.orderlivery-main .code {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 424px;
    margin-top: 72px;
    margin-bottom: 72px;
}

.orderlivery-main .logout-code {
    margin-top: 384px;
}

.orderlivery-main .no-login-box {
    font-size: 40px;
    font-weight: bold;
    position: absolute;
    bottom: 96px;
    left: 0;
    right: 0;
    .no-login-icon {
        width: 2.2rem;
        height: 2.2rem;
        background-color: #eab90e;
        border-radius: 50%;
        .no-login-icon-icon {
            color: #fff;
        }
    }
    .login-text {
        margin: 0 0 0 16px;
    }
}
.FCC {
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderliveryDialogModal .MuiDialog-paperWidthSm {
    width: 1104px;
    /* height: 585px; */
    border-radius: 42px;
}

.goBackDialogModal .MuiDialog-paperWidthSm {
    width: 1104px;
    border-radius: 42px;
}

.goBackDialogModal .dialogName {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 72px;
}

.goBackDialogModal .dialogName .close {
    position: absolute;
    top: 0;
    right: 0;
}

.goBackDialogModal .dialogName .fontStyle {
    font-size: 55px;
    width: 100%;
}

.goBackDialogModal .dialogContent .confirm {
    width: 100%;
    height: 124px;
    background-color: #ffdc33;
    color: #000;
    border-radius: 88px;
}

.orderliveryDialogModal .filter {
    display: flex;
    flex-direction: column;
}

.orderliveryDialogModal .clear-filter {
    font-size: 40px;
    color: #6aaaff;
    width: 100%;
    text-align: right;
}

.orderliveryDialogModal .filter .filter-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
}

.orderliveryDialogModal .filter .filter-content .filter-labeld {
    // height: 94px;
    font-size: 40px;
    color: #989eb6;
    border: 1px solid #ccc;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    margin-right: 24px;
    margin-bottom: 48px;
}

.orderliveryDialogModal .dialogName {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 72px;
}

.orderliveryDialogModal .dialogName .close {
    position: absolute;
    top: 0;
    right: 0;
}

.orderliveryDialogModal .dialogContent {
    display: flex;
    flex-direction: column;
    /* height: 500px; */
}

.orderliveryDialogModal .dialogContent .ok {
    width: 354px;
    height: 144px;
    background-color: #ffdc33;
    color: #000;
    border-radius: 88px;
}

.orderliveryDialogModal .dialogContent .cancel {
    width: 354px;
    height: 144px;
    background-color: #5867b4;
    color: #fff;
    border-radius: 88px;
}

.orderliveryDialogModal input {
    width: 100%;
    min-height: 120px;
    color: #9791bf;
    border-radius: 50px !important;
    padding: 25px 35px;
    word-break: break-word;
    border-radius: 22px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: #f8f8f8;
}

.orderliveryDialogModal .insertCodebtn {
    width: 100%;
    text-transform: capitalize;
}

.orderliveryDialogModal .dialogName .fontStyle {
    font-size: 55px;
    width: 100%;
}

.orderlivery-main .code .code-btn {
    padding: 0;
}

.orderlivery-main .code .qr-code-btn {
    margin-right: 72px;
}

.orderlivery-main .code .qr-code-btn .camera {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.orderlivery-main .code .qr-code {
    width: 372px;
    height: 424px;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qrCodeImg {
    -webkit-filter: contrast(0.1);
    -moz-filter: contrast(0.1);
    -ms-filter: contrast(0.1);
    -o-filter: contrast(0.1);
    filter: contrast(0.1);
    filter: gray;
}

.orderlivery-main .code .insert-code {
    width: 372px;
    height: 424px;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orderlivery-main .code .qr-code img {
    width: 104px;
    height: 104px;
}

.orderlivery-main .code .code-text {
    font-family: "Krub";
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
    color: #000000;
    margin-top: 72px;
    text-transform: initial;
}

.orderlivery-main .code .insert-code img {
    width: 104px;
    height: 104px;
}

.history-restaurant {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.2s;
    width: 100%;
    background-color: #f8f8f8;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
}

.history-restaurant-main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 48px 24px 0;
}

.history-restaurant-main .searchView {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 48px;
    padding-left: 44px;
    padding-right: 44px;
    background-color: #f8f8f8;
    overflow: hidden;
    z-index: 10;
}

.hideSearchView {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f8f8f8;
    overflow: hidden;
    z-index: 0;
    padding: 0;
}

.history-restaurant-main .searchView .search-tool {
    position: relative;
    display: flex;
    align-items: center;
}

.history-restaurant-main .searchView .recently-searched {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 108px;
}

.recently-searched .clear-all {
    font-size: 40px;
    color: #6aaaff;
}

.recently-searched .description-title {
    font-size: 40px;
}

.history-restaurant-main .searchView .recently-searched-list {
    /* display: flex; */
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    height: 65vh;
}

.history-restaurant-main .searchView .recently-searched-list::-webkit-scrollbar {
    display: none;
}

.history-restaurant-main .searchView .recently-searched-list .recently-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
}

.recently-searched-list .recently-history .recently-history-tit {
    color: #bcbcbc;
    font-size: 40px;
}

.recently-searched-list .recently-history .clearIcon {
    color: #bcbcbc;
}

.history-restaurant-main .searchView .search-input {
    position: relative;
    display: flex;
    align-items: center;
    width: 747px;
}

.searchView .search-input .search-tool-icon {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 2vw;
    margin: auto;
    margin-left: 20px;
}

.searchView input {
    width: 100%;
    min-height: 104px;
    color: #000;
    border-radius: 50px !important;
    padding: 20px;
    padding-left: 50px;
    padding-right: 90px;
    word-break: break-word;
    border-radius: 22px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: #fff;
}

.history-restaurant-main .history-restaurant-scroll-list {
    overflow: hidden;
    height: 100%;
    flex: 1;
}

.history-restaurant-main .history-restaurant-scroll-list::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
    background-color: #f7f7f7;
    box-shadow: 0 5px 5px #bcbcbc 50%;
}

.history-restaurant-main .history-restaurant-scroll-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 5px 5px #efefef;
    background: #e9e5e5;
}

.history-restaurant-main .history-restaurant-list {
    // display: flex;
    // align-items: center;
    flex-direction: row;
    min-height: 306px;
    background-color: #ffffff;
    margin-bottom: 24px;
    padding: 24px;
    overflow: hidden;
    box-shadow: 0px 4px 6px 0px #bdbcbc;
    border-radius: 20px;
}

.history-restaurant-main .history-restaurant-list .isNotWithinBox {
    text-align: left;
    margin-top: 24px;
}

.history-restaurant-main .history-restaurant-list .isNotWithinBox .isNotWithin {
    display: inline;
    font-size: 40px;
    margin-left: 0;
    padding: 10px 20px;
    color: #fd7b88;
    background-color: #f9e9e9;
    line-height: 80px;
    border-radius: 10px;
}
.history-restaurant-main .history-restaurant-tool-box {
    display: block;
    height: 80px;
}
.history-restaurant-main .history-restaurant-tool {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.history-restaurant-tool .tune-tool {
    position: relative;
}

.history-restaurant-tool .tune-tool .cue-bubble {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6aaaff;
    color: #fff;
    border-radius: 50%;
    font-size: 30px;
}

.search-tool .tune-tool {
    position: relative;
}

.search-tool .tune-tool .cue-bubble {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6aaaff;
    color: #fff;
    border-radius: 50%;
    font-size: 30px;
}

.history-restaurant-main .history-restaurant-tool .orderlivery-expand {
    color: #bcbcbc;
    width: 72px;
}

.history-restaurant-main .history-restaurant-tool .tool-icon {
    width: 72px;
    height: 72px;
}

.history-restaurant-main .tool-icon {
    width: 72px;
    height: 72px;
}

.margin-right {
    margin-right: 48px;
}

.margin-left {
    margin-left: 48px;
}

.history-restaurant-main .history-restaurant-list .history-restaurant-list-main {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 205px;
    background-color: #ffffff;
}

.history-restaurant-main .history-restaurant-list .restaurant-list-icon {
    display: flex;
    width: 200px;
    height: 200px;
    background-color: #f8f8f8;
    margin-right: 24px;
    border-radius: 22px;
}

.history-restaurant-main .history-restaurant-list .restaurant-list-icon img {
    width: 100%;
    height: 100%;
    border-radius: 22px;
    border-color: transparent;
    overflow: hidden;
    object-fit: cover;
}
.history-restaurant-main .history-restaurant-title-box {
    display: block;
    // height: 120px;
    margin: 24px 0;
}
.history-restaurant-main .history-restaurant-title {
    font-family: "Krub SemiBold", "Krub Regular", "Krub";
    font-weight: 600;
    font-style: normal;
    font-size: 50px;
    color: #020202;
    text-align: center;
    // margin: 72px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 20px;
    .currentAddress {
        color: #9791bf;
        display: flex;
        align-items: center;
        max-width: 50%;
        .currentAddressIcon {
            width: 80px;
        }
        .currentAddressText {
            min-width: calc(100% - 200px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
        }
    }

    .roomIconColor {
        color: #9791bf;
    }
}

.history-restaurant-main .history-restaurant-list .restaurant-list-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 755px;
    height: 100%;
    text-align: left;
}

.history-restaurant-main .history-restaurant-list .restaurant-list-info .restaurant-list-info-tit {
    font-size: 40px;
    font-family: "Krub SemiBold";
    color: #333;
    font-weight: 600;
}

.history-restaurant-main .history-restaurant-list .restaurant-list-info .restaurant-list-info-mark {
    overflow: scroll;
    white-space: nowrap;
    display: flex;
}

.history-restaurant-main .history-restaurant-list .restaurant-list-info .restaurant-list-info-mark::-webkit-scrollbar {
    display: none;
}

.restaurant-list-info .restaurant-list-info-mark .mark-remark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
    background-color: #6aaaff;
    color: #fff;
    font-family: "Krub Medium", "Krub Regular", "Krub";
    font-weight: 500;
    font-style: normal;
    font-size: 40px;
    border-radius: 112px;
    margin-right: 32px;
    padding: 0 50px;
}

.restaurant-list-info .restaurant-list-info-startsTime {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #bcbcbc;
}

.restaurant-list-info .restaurant-list-info-startsTime .startsIcon {
    display: flex;
}

.restaurant-list-info .restaurant-list-info-startsTime .startsIcon .txs-end {
    display: flex;
}

.restaurant-list-info .restaurant-list-info-startsTime .text {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #bcbcbc;
}

.restaurant-list-info .restaurant-list-info-startsTime .endOfMeal {
    color: #6aaaff;
}
