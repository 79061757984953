.account-main * {
    box-sizing: boder-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    font-weight: normal;
}

.myCoupon {
    .content {
        height: calc(100% - 196px) !important;
    }
}

.pos-relative {
    position: relative;
}

.backgroundTransparent {
    background-color: transparent !important;
}

.pos-absoult {
    position: absolute;
}

.overall-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.boxShadow {
    box-shadow: 0 4px 8px 1px #e3e3e3a3;
}

.boxShadow-btn {
    box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627) !important;
}

.boxShadow-card {
    box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
}

.main-body .MuiSelect-icon {
    color: #9791bf;
}

.fontStyle {
    font-family: "Krub SemiBold", "Krub Regular", "Krub";
    font-weight: 600;
    font-style: normal;
    text-align: center;
}

.margin-bottom {
    margin-bottom: 72px;
}

.margin-top {
    margin-top: 72px;
}

.marginTopbottom {
    margin: 50px 0;
}

.textAlign {
    text-align: left;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-between-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.flex-center-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-start-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// $ 主页面样式
.account-main {
    width: 100%;
    background-color: #f8f8f8;

    .account-content::-webkit-scrollbar {
        display: none;
    }

    // 修改地址弹窗
    .addressInput input {
        font-family: "Krub SemiBold", "Krub Regular", "Krub";
    }

    .account-content {
        width: 960px;
        margin: auto;
        padding: 0 6px;
        overflow: auto;
        height: calc(100% - 196px);

        .account-avatar {
            display: flex;
            flex-direction: column;
            border-radius: 70px;
            background-color: #fff;
            padding: 40px 60px;

            .account-avatar-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
            }

            .member-box {
                margin-top: 20px;

                .member-box-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .tit {
                        font-size: 40px;
                        color: #9791bf;
                        font-weight: 500;
                    }

                    .icon {
                        color: #9791bf;
                    }
                }
            }

            .account-avatar-img {
                position: relative;
                width: 168px;
                height: 168px;
                margin-right: 40px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .account-avatar-input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
            }

            .avatar-info-height {
                height: 168px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .avatar-name {
                max-width: 485px;
                font-size: 55px;
                text-align: left;
                line-height: 168px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                margin-bottom: 10px;
            }

            .avatar-email {
                font-weight: 500;
                font-size: 40px;
                color: #bcbcbc;
                text-align: left;
            }

            .icon {
                color: #9791bf;
            }
        }

        .account-info {
            background-color: #fff;
            border-radius: 70px;
            padding: 40px 60px;

            .account-info-tit {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;

                .tit {
                    font-size: 40px;
                    color: #9791bf;
                    font-weight: 500;
                }

                .icon {
                    color: #9791bf;
                }
            }

            .input-main {
                position: relative;
                display: flex;
                flex-direction: row;
                background-color: #fff;
                align-items: center;
                justify-content: center;
                height: 144px;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(207, 207, 207, 1);
                border-radius: 88px;
                overflow: hidden;
            }

            .phone {
                .phone-input {
                    margin: 50px 0;
                }

                .phone-main {
                    .phone-insert-in {
                        width: 600px;
                        background: #fff;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        height: 100%;
                        color: #394474;
                        font-size: 50px;
                        padding-left: 20px;
                    }
                }
            }

            .email {
                .email-input {
                    margin: 50px 0;
                }

                .email-insert-in {
                    background: #fff;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    height: 100%;
                    color: #394474;
                    font-size: 4.63vw;
                    width: 70%;
                    padding: 0 50px 0 0;
                }

                .add-button {
                    background-color: #ffdc33;
                    color: black;
                    border-radius: 88px;
                }

                .del-button {
                    background-color: #e8e7eb;
                    color: #fff;
                    border-radius: 88px;
                }
            }

            .changePassword {
                margin: 20px 0;
            }
        }
    }

    // 编辑页确认按钮
    .confirm {
        width: 792px;
        height: 144px;
        border-radius: 88px;
        background-color: #ffdc33 !important;
        font-size: 50px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
    }
}

.account-tit {
    margin: 50px 0;
    font-size: 40px;
    color: #bcbcbc;
    text-align: left;
    font-weight: 500;
}

.margin50 {
    margin: 50px 0;
}

.third-party {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 439px;
    border-radius: 70px;
    background-color: #fff;

    .face-book {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 60px;

        .icon {
            width: 144px;
            height: 144px;
            background: url("../images/face-book.svg") no-repeat center;
            background-size: 50px;
            border-radius: 50%;
            border: 1px solid #6d7abd;
        }

        .add-button {
            background-color: #5867b4;
            color: #fff;
        }
    }

    .google {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 60px;

        .icon {
            width: 144px;
            height: 144px;
            background: url("../images/google.svg") no-repeat center;
            background-size: 70px;
            border-radius: 50%;
            border: 1px solid #6d7abd;
        }

        .add-button {
            background-color: #ffdc33;
        }
    }

    .add-button {
        width: 200px;
        height: 96px;
        border-radius: 88px;
    }

    .tit {
        font-size: 40px;
        color: #bcbcbc;
        font-weight: 500;
        text-align: left;
        padding-left: 24px;
        flex: 1;
    }

    .account-name {
        font-size: 40px;
        color: #394474;
        word-break: break-word;
    }

    .MuiButton-label {
        text-transform: capitalize;
    }
}

.delivery-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 144px;
    background-color: #fff;
    border-radius: 88px;
    padding: 0px 60px;
    word-break: break-word;

    .tit {
        flex: 8;
        font-size: 40px;
        color: #bcbcbc;
        font-weight: 500;
    }
}

.fiscal-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 144px;
    background-color: #fff;
    border-radius: 88px;
    padding: 0px 60px;

    .tit {
        font-size: 40px;
        color: #bcbcbc;
        font-weight: 500;
    }
}

.icon-left {
    color: #9791bf;
}

// $ 修改手机号
.change-phone-main {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-color: #f8f8f8;
    z-index: 2;
}

.change-phone-content {
    height: calc(100% - 196px);
    margin: 0 auto;
    overflow: hidden;

    .tip-text {
        font-size: 35px;
        color: #bcbcbc;
        text-align: left;
        word-break: break-word;
    }

    .switch-container {
        width: 100%;
        font-size: 30px;
        color: #5E6899;
        margin: 64px 0;
    }

    .phone-input {
        width: 792px;
        margin: 50px 0;
    }

    .phone-main {
        .phone-insert-in {
            width: 600px;
            padding: 20px;
            background: #fff;
            justify-content: center;
            align-items: center;
            border: none;
            height: 100%;
            color: #394474;
            font-size: 50px;
        }
    }

    .input-main {
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        height: 144px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(207, 207, 207, 1);
        border-radius: 88px;
        overflow: visible;
    }

    .confirm {
        width: 792px;
        height: 144px;
        border-radius: 88px;
        background-color: #ffdc33;
        font-size: 50px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        text-transform: none;
    }

    .main-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 50px;

        .account-tit {
            font-weight: 600;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
        }
    }
}

// $ 修改Email
.change-email-main {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-color: #f8f8f8;
    z-index: 2;
}

.change-email-content {
    height: calc(100% - 196px);
    margin: 0 auto;
    overflow: hidden;
    width: 792px;

    .input-main {
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        height: 144px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(207, 207, 207, 1);
        border-radius: 88px;
        overflow: visible;

        .phone-insert-in,
        .email-insert-in {
            border-radius: 88px;
        }
    }

    .code-input-main {
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        align-items: center;
        height: 144px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(207, 207, 207, 1);
        border-radius: 88px;
        overflow: hidden;
        width: 792px;
    }

    .confirm {
        width: 792px;
        height: 144px;
        border-radius: 88px;
        background-color: #ffdc33 !important;
        font-size: 50px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        text-transform: none;
    }

    .main-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 50px;

        .account-tit {
            font-weight: 600;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
        }

        // 验证码input
        .verification-code-insert-in {
            background: #fff;
            justify-content: center;
            align-items: center;
            border: none;
            width: 300px;
            height: 100%;
            color: #394474;
            font-size: 4.63vw;
            padding-right: 90px;
        }
    }

    .email-insert-in {
        background: #fff;
        justify-content: center;
        align-items: center;
        border: none;
        height: 100%;
        color: #394474;
        font-size: 4.63vw;
        width: 100%;
        padding: 0 50px;
    }

    .code-content {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0 40px 0 60px;

        .code-timing {
            .send-code {
                width: 312px;
                height: 94px;
                border-radius: 88px;
                background-color: #ffdc33;
                font-weight: 400;
                padding: 0 20px;
                text-transform: capitalize;
                word-break: break-all;
                white-space: nowrap;
            }
        }
    }
}

// $ 第三方模态框
.thirdPartyModal .dialogName {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 6.667vw;

    .fontStyle {
        font-size: 55px;
        width: 100%;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.thirdPartyModal .MuiDialog-paperWidthSm {
    width: 952px;
    height: 1320px;
    border-radius: 42px;
}

.thirdPartyModal .thirdPartyIcon {
    width: 230px;
    height: 230px;
}

.thirdPartyModal {
    .third-party-main {
        display: flex;
        flex-direction: column;
        align-items: center;

        .description {
            font-family: "Arial Normal", "Arial";
            font-size: 40px;
            color: #bcbcbc;
            text-align: left;
            font-weight: 400;
        }

        .tit {
            font-size: 55px;
        }

        .tit-info {
            font-size: 40px;
            color: #bcbcbc;
            text-align: left;
            font-weight: 400;
            text-align: center;
            margin: 10px 0;
        }
    }

    .thirdPartyIcon img {
        width: 100%;
    }

    .mark-remark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 59px;
        background-color: #6aaaff;
        color: #fff;
        font-family: "Krub Medium", "Krub Regular", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        border-radius: 112px;
        margin-right: 32px;
        padding: 0 50px;
    }
}

// $ 移除第三方账户确认框
.remove-thirdPartyModal .dialogName {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 6.667vw;

    .fontStyle {
        font-size: 55px;
        width: 100%;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.remove-thirdPartyModal .MuiDialog-paperWidthSm {
    width: 952px;
    height: 576px;
    border-radius: 42px;
}

.remove-thirdPartyModal .dialogContent {
    .ok {
        width: 354px;
        height: 144px;
        background-color: #ffdc33;
        color: #000;
        border-radius: 88px;
    }

    .cancel {
        width: 354px;
        height: 144px;
        background-color: #6d7abd;
        color: #fff;
        border-radius: 88px;
    }

    .remove-third-btn {
        text-transform: capitalize;
    }
}

.remove-thirdPartyModal {
    .remove-third-party-main {
        display: flex;
        flex-direction: column;
        align-items: center;

        .description {
            font-size: 40px;
            padding: 20px 40px;
            text-align: center;
        }

        .tit {
            font-size: 55px;
        }

        .tit-info {
            font-size: 40px;
            color: #bcbcbc;
            text-align: left;
            font-weight: 400;
            text-align: center;
            margin: 10px 0;
        }
    }

    .thirdPartyIcon img {
        width: 100%;
    }

    .mark-remark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 59px;
        background-color: #6aaaff;
        color: #fff;
        font-family: "Krub Medium", "Krub Regular", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        border-radius: 112px;
        margin-right: 32px;
        padding: 0 50px;
    }
}

// 删除账户弹窗
.DeleteAccountDialog {
    z-index: 999 !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .MuiDialog-paperWidthSm {
        width: 1104px;
        /* height: 585px; */
        border-radius: 42px;
    }

    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
    }

    .dialogName .close {
        position: absolute;
        top: 0;
        right: 0;
    }

    .dialogContent {
        display: flex;
        flex-direction: column;
        /* height: 500px; */
    }

    .text1 {
        font-weight: bold;
    }

    .dialogContent .ok {
        width: 354px;
        height: 144px;
        background-color: #ffdc33;
        color: #000;
        border-radius: 88px;
    }

    .dialogContent .cancel {
        width: 354px;
        height: 144px;
        background-color: #5867b4;
        color: #fff;
        border-radius: 88px;
    }

    .dialogName .fontStyle {
        font-size: 55px;
        width: 100%;
    }

    .textBox {
        font-size: 45px;
        padding-bottom: 72px;
    }
}