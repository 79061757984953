.blackGoldRestaurantInfo {
    ::-webkit-scrollbar {
        display: none;
    }
    .blackGoldRestaurantInfoContent {
        background: #282733;
        color: #b5b4b4;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
        overflow-y: scroll;
    }
    .markdown {
        min-height: 500px;
    }
    .linebox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        .line {
            width: 150px;
            height: 20px;
            background-color: #000;
            border-radius: 10px;
        }
    }
    .dialogbody {
        text-align: center;
        // width: 100%;
        padding: 48px 16px;
        .imgbox {
            height: 300px;
            width: 300px;
            margin: 0 auto;
            .restaurant-logo {
                border-radius: 50px;
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                width: 100%;
                max-height: 100%;
            }
        }
        .restaurantname {
            padding: 10px 0;
            p {
                font-size: 55px;
                font-weight: bold;
            }
            span {
                font-size: 40px;
                color: #cfcfcf;
                display: block;
                padding-top: 10px;
            }
        }
        .chipbox {
            margin-top: 48px;
            div {
                margin-right: 24px;
                margin-top: 5px;
            }
            .MuiChip-root {
                background-color: #b07c4d;
                color: #fff;
                padding: 0 15px;
            }
        }
        .introductiontext {
            text-align: left;
            margin-top: 30px;
            font-size: 46px;
            word-wrap: break-word;
        }
    }
}
