.countrySelectBox {
    width: 120px;
    color: #333;
    .search-pre {
        padding-right: 20px !important;
        padding-left: 0 !important;
    }
    .drop-pre {
        width: unset !important;
        max-height: 1000px !important;
        text-align: left;
    }
    .button-pre {
        position: relative !important;
        top: 0;
        bottom: 0;
        padding: 0;
        background-color: rgba(255, 255, 255, 0) !important;
        border: 1px solid rgba(255, 255, 255, 0) !important;
        border-radius: 3px 0 0 3px;
        // width: 40px;
        height: 55px;
    }
    .country-list .country{
        display: flex;
    }
}
