.sidebarContainer {
    // position: absolute;
    // display: flex;
    // flex-direction: column-reverse;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // background: rgba(0,0,0,.3);
    width: 795px;
    height: 100%;
}

.sidebarContent {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 0 10%;
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        padding-bottom: 80px;
        border-bottom: 2px solid #eaebef;
        .imgContainer {
            width: 216px;
            height: 216px;
            border-radius: 200px;
            background: #fcfcfc;
            border: 1px solid #666;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 200px;
            }
        }
        .isNoLogin {
            width: 216px;
            height: 216px;
            border-radius: 200px;
            background: #fcfcfc;
            border: none;
            line-height: 216px;
            text-align: center;
            overflow: hidden;
            .accountIcon {
                display: block;
                width: 216px;
                height: 216px;
                color: #bcbcbc;
            }
        }
        .userName {
            font-weight: 600;
            font-style: normal;
            font-size: 50px;
            color: #000000;
            margin: 48px 0 0;
            line-height: 72px;
        }
        .email {
            font-style: normal;
            font-size: 40px;
            color: #bcbcbc;
            line-height: 58px;
        }
        .testText {
            font-size: 40px;
            border: 1px solid #9791bf;
            border-radius: 25px;
            padding: 10px 15px;
            color: #9791bf;
        }
    }
    // .loginHeader {
    // padding-bottom: 85px;
    // }
    .content {
        display: flex;
        flex: 1;
        border-bottom: 1px solid #eaebef;
        margin-top: 75px;
        // height: 600px;
        overflow-y: scroll;
        ul {
            display: block;
            width: 100%;
        }
        li {
            display: block;
            margin-bottom: 92px;
            .IconButtonContainer {
                display: inline-block;
                padding: 0;
                width: 72px;
                height: 72px;
                .icoImg {
                    font-size: 72px !important;
                    color: #000000;
                }
                margin-right: 45px;
            }
            span {
                display: inline-block;
                font-size: 50px;
                color: #000000;
                vertical-align: middle;
            }
        }
        .MuiBadge-dot{
            right: -15px;
        }
    }
    footer {
        margin: 95px 0;
        font-size: 50px;
        font-weight: bold;
        text-align: center;
    }
}
