.CouponList {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f8f8f8;

    .backgroundTransparent {
        background-color: transparent !important;
    }

    .content {
        height: calc(100% - 196px - 288px);
        overflow-y: scroll;
        overflow-x: hidden;
        .notfind{
            font-size: 30px;
        }
    }
    .restaurantName {
        text-align: left;
        font-size: 48px;
        width: 900px;
        margin: 0 auto 48px;
        font-family: "kurb Medium";
    }
    .isCheckCoupon {
        width: 900px;
        margin: 0 auto 30px;
        padding: 0px;
        position: relative;
        .couponItem {
            width: 100%;
            margin-bottom: 30px;
            transform: translate3d(72px, 0px, 0px);
            transition: transform 0.1s;
        }
    }
    .couponItem {
        // height: calc(176px - 48px);
        // width: calc(900px - 48px);
        height: calc(311px - 48px);
        width: calc(900px - 48px);
        padding: 24px;
        margin: 0 auto 30px;
        border-radius: 35px;
        // background-image: linear-gradient(to right, #dc69b7, #5250ec);
        background-color: #0d0544;
        transition: transform 0.1s;
        box-sizing: unset;

        .checkedItem {
            // background-image: linear-gradient(to right, #9f4e83, #233a86);
            background-color: #0c072b;
        }
        .couponItem:active {
            // background-image: linear-gradient(to right, #9f4e83, #233a86);
            background-color: #0c072b;
        }
        .disabledItem {
            opacity: 0.5;
        }
        .disabledItem:active {
            // background-image: linear-gradient(to right, #dc69b7, #5250ec);
            background-color: #0c072b;
        }
        .doneicon {
            width: 48px;
            height: 48px;
            border-radius: 48px;
            font-weight: bold;
            color: #ffdc33;
            background: #3d3c69;
        }
        .placeholderBox {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: calc(176px - 48px);
            padding: 24px 0;
            margin-right: 24px;
            border-radius: 48px;
            margin-bottom: 30px;
        }
        // .row1 {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-start;
        //     width: 100%;
        //     height: 50%;
        //     font-family: "kurb Medium";
        //     font-size: 50px;
        //     color: #fff;
        //     line-height: 50px;
        //     .col1 {
        //         width: 40%;
        //         text-align: left;
        //         white-space: nowrap;
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         word-break: break-word;
        //         line-height: 60px;
        //     }
        //     .col2 {
        //         width: 60%;
        //         text-align: left;
        //         white-space: nowrap;
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         word-break: break-word;
        //         line-height: 60px;
        //     }
        // }
        // .row2 {
        //     font-size: 30px;
        //     line-height: 30px;
        // }
        .row1 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 80%;
            font-family: "kurb Medium";
            color: #fff;
            .col1 {
                font-size: 80px;
                font-weight: bold;
                border-right: 1px solid #bcbcbc;
                width: 40%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                line-height: 60px;
                color: #ffdc33;
                padding-right: 10px;
            }
            .col2 {
                margin-left: 15px;
                width: 60%;
                height: 100%;
                padding-top: 15px;
                font-size: 40px;
                display: flex;
                align-items: center;
                span {
                    text-align: left;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-word;
                    // line-height: 60px;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
        .row2 {
            font-size: 30px;
            display: flex;
            // align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 20%;
            font-family: "kurb Medium";
            // font-size: 50px;
            color: #fff;
            .col1 {
                padding-right: 10px;
                // box-sizing: border-box;
                width: 40%;
                border-right: 1px solid #bcbcbc;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                line-height: 60px;
            }
            .col2 {
                margin-left: 15px;
                width: 60%;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                line-height: 60px;
            }
        }
        .description {
            width: 900px;
            margin: 0 auto 50px;
            font-size: 30px;
            color: #c3c3c3;
            text-align: left;
            transition: transform 0.1s;
        }
        .checkedDescription {
            color: #c3c3c3;
            transform: translate3d(72px, 0px, 0px);
            transition: transform 0.1s;
        }
        .notfind {
            width: 100vw;
            height: calc(100% - 196px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 60px;
            font-family: Medium;
        }
    }
    .footerBox {
        height: 288px;
        display: flex;
        .footerContent {
            width: 900px;
            margin: 72px auto;
            display: flex;
            justify-content: space-between;
            .selectText {
                height: 100%;
                font-size: 40px;
                color: #9791bf;
                font-family: "kurb Medium";
                width: calc(100% - 328px);
                display: flex;
                align-items: center;
            }
            .number {
                color: #5867b4;
                padding: 10px;
            }
            .confirmbutton {
                width: 328px;
                height: 144px;
                .checkoutBtn {
                    width: 100%;
                    height: 100%;
                    flex: 1;
                    font-weight: 500;
                    font-style: normal;
                    text-align: center;
                    height: 144px;
                    background: inherit;
                    background-color: #ffdc33;
                    border-radius: 88px;
                    -moz-box-shadow: 0px 10px 20px #86713f1c;
                    -webkit-box-shadow: 0px 10px 20px #86713f1c;
                    box-shadow: 0px 10px 20px #86713f1c;
                    font-size: 50px;
                    border: none;
                }
                .unCheckBth {
                    background-color: #bcbcbc;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        display: block;
    }
}
