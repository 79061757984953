.dialog {
    .MuiPaper-rounded {
        border-radius: 42px;
    }
    .title {
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        .titleText {
            width: 90%;
            font-size: 55px;
            color: #000000;
            font-weight: bold;
        }
        .clearIcon {
            position: absolute;
            right: 0;
            top: 0;
            .closeIcon {
                height: 72px;
                width: 72px;
            }
        }
    }
    .dialogContent {
        .dialogContentText {
            font-size: 40px;
            color: #000000;
        }
    }
    .MuiDialogActions-root {
        display: flex;
        justify-content: space-between;
        padding: 0 30px 60px;
        .ok {
            width: 354px;
            height: 144px;
            background-color: #ffdc33;
            color: #000;
            border-radius: 88px;
        }
        .cancel {
            width: 354px;
            height: 144px;
            background-color: #6d7abd;
            color: #fff;
            border-radius: 88px;
        }
        .remove-third-btn {
            text-transform: capitalize;
            font-size: 40px;
        }
    }
}
