.ReorderDialog {
    z-index: 999 !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .MuiDialog-paper {
        width: 1104px;
        border-radius: 42px;
    }

    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
    }

    .dialogName .close {
        position: absolute;
        top: 0;
        right: 0;
    }
    .content {
        margin: 32px 0;
        overflow-y: scroll;
        max-height: 60vh;
    }
    .content-title {
        font-size: 40px;
        color: #fdce33;
        font-weight: bold;
    }
    .dish-text-box {
        padding: 16px 0;
        .dish-text {
            color: #6c6c6c;
            font-weight: bold;
            margin-bottom: 32px;
            font-size: 40px;
            word-break: break-word;
        }
    }

    .ReorderDialog-dialogContent .btn {
        display: flex;
        flex-direction: column;
        color: #000;
        border-radius: 88px;
        background-color: #ffdc33;
        flex: 1;
    }

    .dialogName .fontStyle {
        font-size: 55px;
        width: 100%;
    }
}
