.couponItemBox {
    .isCheckCoupon {
        width: 920px;
        margin: 30px auto;
        padding: 0px;
        position: relative;
        .couponItem {
            width: 100%;
            margin-bottom: 30px;
            transform: translate3d(72px, 0px, 0px) !important;
            transition: transform 0.1s;
        }
    }

    .couponItem {
        height: calc(311px - 48px);
        width: calc(920px - 48px);
        padding: 24px;
        margin: 30px auto;
        border-radius: 35px;
        background-color: #0d0544;
        transition: transform 0.1s;
        display: flex;
        position: relative;
        // 处理ios点击有阴影
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        .showNewTag {
            background-color: #ff8058;
            color: #fff;
            display: flex;
            padding: 8px 24px;
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 30px;
            transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            align-items: center;
            border-radius: 50px;
            justify-content: center;
            transform: translateY(-24px);
        }
        .fullScreenRules {
            color: #fff;
            text-align: left;
            overflow: auto;
            font-family: "kurb";
            font-size: 35px;
            width: 100%;
            .exemptionRulesText {
                display: block;
            }
        }
        .backGroundVal {
            z-index: 1;
            position: absolute;
            right: 100px;
            bottom: -30px;
            color: #fff;
            font-size: 250px;
            font-weight: bold;
            opacity: 0.2;
        }
        .first {
            bottom: -40px;
        }
        .second {
            bottom: -35px;
        }
        .ruleText {
            width: 100%;
            height: 263px;
        }
        .restaurantCouponItem {
            width: 80%;
            height: 100%;
            // flex: 1;
        }
        .myCouponItem {
            width: 80%;
            height: 100%;
        }
        .getBox {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 35px 35px 0;
            width: 20%;
            height: 100%;
            background-color: #02000f;
            overflow: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "kurb Medium";
            font-size: 40px;
            font-weight: bold;
            color: #ffdc33;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            .IoQrCode {
                width: 80px;
                height: 80px;
                color: #fff;
                margin-top: 30px;
            }
            .IoQrCodeText {
                margin-top: 24px;
                font-size: 30px;
                color: #fff;
            }
        }
    }

    .checkedItem {
        border: 10px solid #ffdc33;
    }
    // .couponItem:active {
    //     background-color: #0c072b;
    // }
    .disabledItem {
        background-color: #67656c;
        opacity: 0.7;
    }
    // .disabledItem:active {
    //     background-color: #0c072b;
    // }
    .qrCodeBox{
        display: flex;
        flex-direction: column;
        height: 100%;
        font-weight: 500;
    }
    .CheckedBox {
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .doneicon {
            width: 60px;
            height: 60px;
            border-radius: 60px;
            font-weight: bold;
            color: #000;
            background: #ffdc33;
        }
    }
    .coupoCodeTextBox {
        height: 20%;
        .coupoCodeText {
            font-size: 24px;

        }
    }
    .row1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 80%;
        font-family: "kurb Medium";
        color: #fff;
        .col1 {
            font-size: 80px;
            font-weight: bold;
            border-right: 1px solid #bcbcbc;
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
            line-height: 60px;
            color: #ffdc33;
            padding-right: 10px;
        }
        .col2 {
            margin-left: 15px;
            width: 60%;
            height: 100%;
            padding-top: 15px;
            font-size: 40px;
            display: flex;
            align-items: center;
            // justify-content: center;
            overflow: hidden;
            span {
                line-height: 50px;
                // height: inherit;
                text-align: left;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
    }
    .row2 {
        font-size: 30px;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 20%;
        font-family: "kurb Medium";
        color: #fff;
        .col1 {
            padding-right: 10px;
            width: 40%;
            border-right: 1px solid #bcbcbc;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
            line-height: 60px;
        }
        .col2 {
            margin-left: 15px;
            width: 60%;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
            line-height: 60px;
        }
    }
    .description {
        width: 900px;
        margin: 0 auto 50px;
        font-size: 30px;
        color: #c3c3c3;
        text-align: left;
        transition: transform 0.1s;
        font-family: "kurb Medium";
        .exemptionRulesText {
            display: block;
            .title {
                font-weight: bold;
            }
        }
    }
    .checkedDescription {
        color: #c3c3c3;
        transform: translate3d(72px, 0px, 0px);
        transition: transform 0.1s;
    }
}
