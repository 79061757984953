.orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 171px;
    background: #f8f8f8;

    .left_header_ico {
        font-size: 80px;
        margin-left: 32px;
        color: "#fff";
    }

    .right_header_ico {
        font-size: 60px;
        margin-right: 32px;
        color: "#fff";
    }

    p {
        font-size: 45px;
        font-weight: bold;
        font-family: Regular;
    }

    span {
        font-size: 40px;
        color: #000000;
        display: block;
    }

    .icoButton {
        display: flex;
        align-items: center;
        width: 140px;
    }
}

.secOrderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 171px;
    background: #282733;

    .left_header_ico {
        font-size: 80px;
        margin-left: 32px;
        color: "#fff";
    }

    .right_header_ico {
        font-size: 60px;
        margin-right: 32px;
        color: "#fff";
    }

    .tool-icon {
        filter: invert(1);
    }

    .searchBar {
        display: flex;
        flex-direction: row-reverse;
        flex: 1;
        margin-left: 32px;
        margin-right: 60px;
        margin-bottom: 15px;
        border-radius: 171px;
        background-color: #36353c;

        // height: 80px;
        .icoButton {
            .MuiIconButton-label {
                color: #fff;
            }
        }

        .MuiIconButton-root {
            padding: 5px 0;
        }
    }

    .icoButton {
        display: flex;
        align-items: center;
        width: 140px;
    }
}

.mainHeaderStyle {
    background: none;
    height: 150px;
}

.slideContainer {
    display: inline-block;
    height: 106px;
    white-space: nowrap;
    padding: 0 10px;

    div {
        margin: 0 24px;
    }

    .MuiChip-clickable:hover,
    .MuiChip-clickable:focus {
        background: #f2f2f7;
    }

    .MuiChip-clickable,
    .MuiChip-clickable {
        background: #f2f2f7;
        color: #989eb6;
        font-size: 35px;
        height: 88px;
    }

    .activeBg,
    .activeBg:hover,
    .activeBg:focus {
        background: #ffdc33;
        color: #000000;
        font-size: 40px;
    }
}

.secondSlide {
    display: inline-block;
    height: 106px;
    white-space: nowrap;
    padding: 0 10px;

    div {
        margin: 0 24px;
    }

    .MuiChip-clickable:hover,
    .MuiChip-clickable:focus {
        background: #36353c;
    }

    .MuiChip-clickable,
    .MuiChip-clickable {
        background: #36353c;
        color: #ffffff;
        font-size: 35px;
        font-family: "Krub";
        height: 88px;
    }

    .activeBg,
    .activeBg:hover,
    .activeBg:focus {
        background: #b07c4d !important;
        color: #fff;
        font-family: "Krub";
        font-size: 35px;
    }
}

.DishList {
    h6 {
        font-size: 40px;
        text-align: left;
        margin-left: 46px;
        padding: 32px 0;
        color: #989eb6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li {
        padding: 12px 24px;
    }
}

.secDishList {
    h6 {
        font-size: 40px;
        text-align: center;
        // margin-left: 46px;
        padding: 25px 0;
        color: #4e4d53;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li {
        padding: 12px 24px;
    }
}

.dishListItemBox {
    .dishHead {
        width: 100%;
        height: 72px;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        font-size: 40px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dishImgboxDisabled {
        box-shadow: unset !important;
    }

    .dishListItem {
        display: flex;
        align-items: center;
        height: 250px;
        box-shadow: 0px 10px 15px 0px #e3e7f4;
        border-radius: 25px;
        padding: 12px 32px;
        background: #fff;
        position: relative;

        .clickBox {
            position: absolute;
            width: calc(100% - 250px - 64px);
            height: 90%;
            overflow: hidden;
            z-index: 2;
        }


        .dishImgbox {
            width: 200px;
            height: 200px;
            background: #f5f5f5;
            border-radius: 40px;
            position: relative;

            .dishImg {
                min-width: 100%;
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 40px;
            }

        }

        .content {
            height: 200px;
            padding-left: 32px;
            width: calc(100% - 232px);

            p {
                font-size: 40px;
                font-weight: bold;
            }

            .center {
                min-height: 72px;
                max-height: 88px;
                text-align: left;
                display: flex;
                align-items: center;
            }

            .dishListtitle {
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                word-break: break-word;
            }

            .dishListdescription {
                color: #bcbcbc;
                width: 100%;

                p {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                }
            }

            .pricebox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 60px;

                .priceboxprice {
                    color: #303460;
                    display: flex;
                    align-items: center;
                    width: calc(100% - 250px);
                    flex-wrap: wrap;
                }

                .fewPart {
                    font-size: 36px;
                    color: #5867b4;
                    border: 1px solid #5867b4;
                    border-radius: 50px;
                    padding: 2px 18px;
                    margin-left: 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .priceNumber {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 250px;
                }

                .MuiIconButton-root {
                    padding: 0;
                    width: 80px;
                    height: 80px;
                }

                .number {
                    margin: 0 32px;
                    color: #000000;
                }

                .is_no_number {
                    color: #bcbcbc;
                }
            }

            .AiFillPlusCircle {
                width: 100%;
                height: 100%;
                color: #ffdc33;
            }

            .AiFillMinusCircle {
                width: 100%;
                height: 100%;
                color: #acacac;
            }

            .no_number_Circle {
                color: #e8e7eb;
            }

            .setMenuBtn {
                background-color: #ffdc33;
                border-radius: 70px;
                line-height: inherit;
                padding: 16px 48px;
                font-size: 40px;
                font-family: "Krub";
                font-weight: bold;
            }

            .disSetMenuBtn {
                background-color: #e8e7eb;
                color: #bcbcbc;
            }
        }

        .dish-tip {
            text-align: right;
            font-size: 30px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
        }
    }

    .noTopRadius {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.secDishItemBox {
    .dishHead {
        width: 100%;
        height: 72px;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
        font-size: 40px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .noTopRadius {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .setMenuBtnBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 250px;
        height: 100%;

        .setMenuBtn {
            background-color: #b07c4d;
            border-radius: 70px;
            margin-right: 48px;
            line-height: inherit;
            padding: 16px 48px;
            font-size: 40px;
            font-family: "Krub";
            font-weight: bold;
        }
    }
}

.carDishListItem {
    display: flex;
    align-items: center;
    border-radius: 70px;
    height: 170px;
    padding: 12px 32px;
    background: #34333b;
    position: relative;

    .clickBox {
        position: absolute;
        width: calc(100% - 250px - 64px);
        height: 90%;
        overflow: hidden;
    }

    .dishImgbox {
        width: 200px;
        height: 128px;
        background: #f5f5f5;
        border-radius: 50px;

        .dishImg {
            min-width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50px;
        }
    }

    .content {
        height: 128px;
        padding-left: 32px;
        // padding-top: 70px;
        width: calc(100% - 232px);

        p {
            font-size: 40px;
            font-weight: bold;
        }

        .center {
            min-height: 72px;
            max-height: 88px;
            text-align: left;
            display: flex;
            align-items: center;
        }

        .dishListtitle {
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-word;
        }

        .dishListdescription {
            color: #bcbcbc;
            width: 100%;

            p {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
        }

        .pricebox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;

            .priceboxprice {
                color: #b07c4d;
                display: flex;
                align-items: center;
            }

            .fewPart {
                font-size: 36px;
                color: #5867b4;
                border: 1px solid #5867b4;
                border-radius: 50px;
                padding: 2px 18px;
                margin-left: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .priceNumber {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 250px;
            }

            .MuiIconButton-root {
                padding: 0;
                width: 80px;
                height: 80px;
            }

            .number {
                margin: 0 32px;
                color: #ffffff;
            }

            .is_no_number {
                color: #bcbcbc;
            }
        }

        .AiFillPlusCircle {
            color: #b07c4d;
        }

        .AiFillMinusCircle {
            color: #b07c4d;
        }

        .no_number_Circle {
            color: #e8e7eb;
        }
    }
}

.secdishListItem {
    display: flex;
    align-items: center;
    height: 250px;
    // box-shadow: 0px 10px 1.481vw 0px #e3e7f4;
    border-radius: 70px;
    padding: 24px 0px 24px 24px;
    background: #36353c;
    position: relative;

    .clickBox {
        position: absolute;
        width: calc(100% - 250px - 64px);
        height: 90%;
        overflow: hidden;
    }

    .dishImgbox {
        width: 450px;
        height: 250px;
        // background: linear-gradient(to right, #28292e,#16171b);
        border-radius: 50px;
        margin-right: 24px;

        .dishImg {
            min-width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 40px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: calc(100% - 450px);

        p {
            font-size: 40px;
            font-weight: bold;
        }

        .center {
            min-height: 72px;
            max-height: 88px;
            text-align: left;
            display: flex;
            align-items: center;
        }

        .dishListtitle {
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .dishListdescription {
            color: #b5b4b4;
            width: 100%;

            p {
                font-size: 35px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .pricebox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;
            // margin-right: 24px;

            .priceboxprice {
                color: #a88968;
                display: flex;
                align-items: center;
                width: 100%;

                .fewPart {
                    font-size: 36px;
                    color: #a88968;
                    border: 1px solid #a88968;
                    border-radius: 50px;
                    padding: 2px 18px;
                    margin-left: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .priceNumber {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                background-color: #b07c4d;
                border-radius: 70px 0 70px 0;
                position: absolute;
                right: 0px;
                bottom: 0px;
            }

            .MuiIconButton-root {
                padding: 0;
                width: 72px;
                height: 72px;
            }

            .number {
                margin: 0 32px;
                color: #000000;
            }

            .is_no_number {
                color: #bcbcbc;
            }
        }

        .AiFillPlusCircle {
            color: #fff;
        }

        .AiFillMinusCircle {
            color: #acacac;
        }

        .no_number_Circle {
            color: #e8e7eb;
        }
    }
}

.orderFloatDialog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 232px;
    background-image: linear-gradient(to right, #4c4b82, #161626);
    border-radius: 150px;
    box-shadow: 0px 25px 25px 0px #989eb6;

    .MuiIconButton-root:hover {
        background-color: #717096;
    }

    .iconContainer {
        height: 200px;
        width: 200px;
        border-radius: 140px;
        background: #8a8e9d;
        margin: 0 20px;
        color: #989eb6;
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiIconButton-label {
            width: unset;
        }

        .PhoneIcon {
            height: 100px;
            width: 100px;
        }

        .IoLogoWhatsappIcon {
            height: 135px;
            width: 135px;
            color: #4a456c;
            display: flex;
        }

        // .phoneIconbox::after {
        //     position: absolute;
        //     content: "";
        //     border-top: 30px solid #4a456c;
        //     border-left: 20px solid transparent;
        //     border-right: 20px solid transparent;
        //     border-bottom: 20px solid transparent;
        //     transform: translate3d(-55px, 70px, 0px) rotate(35deg);
        // }
        .cartIconbox {
            .cartNum {
                display: block;
                font-size: 40px;
                color: #0d0544;
                font-weight: bold;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 20px;
        height: 100%;
        flex: 1;

        .bag {
            font-size: 40px;
            text-align: left;
            color: #fff;
            -moz-user-select: none;
            /* 火狐 */
            -webkit-user-select: none;
            /* 谷歌 */
            -ms-user-select: none;
            /* IE */
            user-select: none;

            .totalTip {
                background: #5867b4;
                border-radius: 50px;
                padding: 1px 24px;
                // width:50px;
                line-height: 56px;
                margin-bottom: 16px;
                display: inline-block;
                white-space: nowrap;
            }

            .priceBox {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .price {
                    font-size: 45px;
                    color: #ffdc33;
                    font-weight: 700;
                }
            }
        }

        .totalPrice {
            display: flex;
            color: #ffdc33;
            overflow: auto;
            height: 100%;
            // width: 100%;
            align-items: center;
            justify-content: center;

            .leastPrice {
                display: inline-block;
                font-size: 30px;
                line-height: 60px;
            }

            .leastPriceAnimation {
                animation: leastPriceAnimation 0.5s linear 0s infinite alternate;
            }
        }
    }
}

.secorderFloatDialog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    height: 232px;
    padding: 0 48px;
    background-color: #000000;
    position: relative;

    .MuiIconButton-root:hover {
        background-color: #717096;
    }

    .content {
        box-sizing: border-box;
        padding: 20px;
        flex: 1;

        .bag {
            position: absolute;
            left: 20px;
            top: -25px;
            font-size: 40px;
            text-align: left;
            color: #fff;
            -moz-user-select: none;
            /* 火狐 */
            -webkit-user-select: none;
            /* 谷歌 */
            -ms-user-select: none;
            /* IE */
            user-select: none;

            span {
                background-color: #a97646;
                border-radius: 50px;
                padding: 1px 24px;
                // width:50px;
                line-height: 56px;
                white-space: nowrap;
            }
        }

        .totalPrice {
            display: flex;
            margin-top: 20px;
            color: #fff;

            .price {
                font-size: 50px;
                font-weight: 700;
            }

            .leastPrice {
                display: inline-block;
                font-size: 30px;
                line-height: 60px;
                margin-left: 24px;
            }

            .leastPriceAnimation {
                animation: leastPriceAnimation 0.5s linear 0s infinite alternate;
            }
        }
    }

    .rightIcon {
        .iconContainer {
            height: 150px;
            width: 150px;
            border-radius: 75px;
            background: #36353c;
            text-align: center;
            // margin: 0 20px;
            margin-right: 24px;
            color: #fff;
        }
    }
}

@keyframes leastPriceAnimation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.orderSearch {
    box-sizing: border-box;
    padding: 0 47px;
    position: relative;

    .searchContent {
        padding: 0 47px;
        overflow: scroll;
        position: fixed;
        bottom: 100px;
        left: 0;
        right: 0;
        top: 300px;
    }

    header {
        display: flex;
        align-items: center;

        .inputContainer {
            position: relative;
            flex: 1;

            .seachVal {
                box-sizing: border-box;
                width: 100%;
                border: 1px solid rgba(207, 207, 207, 1);
                height: 80px;
                border-radius: 80px;
                padding-left: 40px;
                font-size: 40px;
            }

            input::-webkit-input-placeholder {
                font-size: 40px;
            }

            .secSeachVal {
                box-sizing: border-box;
                width: 100%;
                border: 1px solid #36353c;
                height: 80px;
                border-radius: 80px;
                padding-left: 40px;
                font-size: 40px;
                color: #ffffff;
                background-color: #36353c;
            }

            .searchIcon {
                display: flex;
                align-items: center;
                position: absolute;
                top: 2px;
                right: 10px;
                font-size: 60px;
                width: 80px !important;
                height: 80px !important;
                padding: 0;
            }

            .secSearchIcon {
                display: flex;
                align-items: center;
                position: absolute;
                top: 2px;
                right: 10px;
                font-size: 60px;
                width: 80px !important;
                height: 80px !important;
                padding: 0;
                font-weight: bold;
                color: #ffffff;
            }
        }

        .closeIco {
            font-weight: bold;
            color: #333333;
        }

        .secCloseIco {
            font-weight: bold;
            color: #ffffff;
        }
    }

    .searchHistory {
        .title {
            text-align: left;
            font-family: "Krub SemiBold", "Krub Regular", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 40px;
            color: #020202;
            line-height: 72px;
        }

        .secTitle {
            text-align: left;
            font-family: "Krub SemiBold", "Krub Regular", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 40px;
            color: #bcbcbc;
            line-height: 72px;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 120px;

            span {
                color: #cfcfcf;
                font-size: 40px;
            }
        }
    }
}

.orderShopCatDialog {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // position: relative;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding-bottom: 300px;
    max-height: calc(100% - 171px);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    z-index: 99;

    .tipBox {
        border-width: 0px;
        position: absolute;
        left: 200px;
        top: 0px;
        width: 704px;
        height: 100px;
        background: inherit;
        background-color: rgba(33, 33, 57, 1);
        border: none;
        border-radius: 30px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        line-height: 100px;

        &::after {
            position: absolute;
            bottom: -21px;
            right: calc(50% - 12px);
            content: "";
            border-left: 23px solid transparent;
            border-right: 23px solid transparent;
            border-top: 23px solid rgba(33, 33, 57, 1);
        }

        span {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #ffffff;
            text-align: center;
            // white-space: pre-wrap;
        }
    }

    header>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        margin: 0 48px;
        border-bottom: 1px solid #bcbcbc3b;

        .deleteBtn {
            width: 65px;
            height: 65px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        p {
            font-size: 40px;
            font-weight: bold;
            color: #000;
        }

        span {
            font-weight: normal;
            color: #bcbcbc;
            padding: 20px;
        }
    }

    .shopList {
        height: calc(100% - 120px);
        overflow-y: scroll;
    }
}

.secorderShopCatDialog {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // position: relative;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(40, 39, 51, 0.8);
    padding-bottom: 300px;
    max-height: calc(100% - 171px);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    z-index: 99;

    .tipBox {
        border-width: 0px;
        position: absolute;
        left: 200px;
        top: 0px;
        width: 704px;
        height: 100px;
        background: inherit;
        background-color: #36353c;
        border: none;
        border-radius: 30px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        line-height: 100px;

        &::after {
            position: absolute;
            bottom: -21px;
            right: calc(50% - 12px);
            content: "";
            border-left: 23px solid transparent;
            border-right: 23px solid transparent;
            border-top: 23px solid #36353c;
        }

        span {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #ffffff;
            text-align: center;
        }
    }

    header>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        margin: 0 48px;

        .deleteBtn {
            // width: 137px;
            // height: 48px;

            p {
                font-size: 35px;
                // display: block;
                // width: 100%;
            }
        }

        p {
            font-size: 40px;
            font-weight: bold;
            color: #a97646;
        }

        span {
            font-weight: normal;
            color: #bcbcbc;
            padding: 20px;
        }
    }

    .shopList {
        height: calc(100% - 120px);
        overflow-y: scroll;
    }
}

.RemarksDialog {
    overflow: hidden;

    .MuiDialog-paperFullWidth,
    .MuiPaper-root {
        border-radius: 70px;
    }

    .textBox {
        display: flex;
        flex-direction: column;

        label {
            font-family: "Krub Medium", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
            margin-bottom: 48px;
            text-align: center;
            margin-left: 0;
        }
    }

    .head {
        height: 171px;
        display: flex;
        // align-items: center;
        justify-content: center;
        font-size: 55px;
        font-weight: bold;
        margin: 50px 100px 0;
        word-break: break-word;
        overflow: scroll;
    }

    .addNoteText {
        box-sizing: border-box;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
        // width: 816px;
        height: 500px;
        background: inherit;
        background-color: rgba(248, 248, 248, 1);
        border: none;
        border-radius: 70px;
        padding: 48px 48px;
        margin: 0 72px;

        &::-webkit-input-placeholder {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
        }
    }

    .remarksbuttons {
        // height: 200px;
        margin: 72px 72px;
        display: flex;
        background-color: transparent;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 144px);

        .MuiIconButton-root {
            padding: 0;
            border-radius: 50px;
        }

        .okbutton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 360px;
            height: 120px;
            border-radius: 50px;
            background: #ffdc33;
            color: #000000;
        }

        .cancelbutton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 360px;
            height: 120px;
            border-radius: 50px;
            background: #5867b4;
            color: #ffffff;
        }

        .orderbold {
            font-size: 50px;
        }

        .orderway {
            font-size: 40px;
            padding-left: 10px;
            font-weight: normal;
            font-family: Regular;
        }

        .disable {
            color: #fff !important;
            opacity: 0.5;
            background: #323161;
        }
    }
}

.MuiDrawer-paper {
    background-color: transparent !important;
}

.SecRemarksDialog {
    overflow: hidden;
    background-color: #282733;
    border-radius: 64px 64px 0 0;

    .textBox {
        display: flex;
        flex-direction: column;

        label {
            font-family: "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 25px;
            color: #8c949f;
            margin-bottom: 48px;
            text-align: center;
            margin-left: 0;
        }
    }

    .head {
        // height: 171px;
        display: flex;
        // align-items: center;
        justify-content: center;
        margin-top: 36px;
        margin-bottom: 64px;

        div {
            box-sizing: border-box;
            width: 120px;
            height: 15px;
            background-color: #000000;
            border-radius: 15px;
        }
    }

    .addNoteText {
        box-sizing: border-box;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #8c949f;
        // width: 816px;
        height: 600px;
        background: inherit;
        background-color: #3e3c4c;
        border: none;
        border-radius: 10px;
        padding: 48px 16px;
        margin: 0 16px;

        &::-webkit-input-placeholder {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
        }
    }

    .remarksbuttons {
        // height: 200px;
        margin: 24px 72px;
        display: flex;
        background-color: transparent;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 144px);

        .MuiIconButton-root {
            padding: 0;
            border-radius: 50px;
        }

        .okbutton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 360px;
            height: 120px;
            border-radius: 10px;
            font-size: 13px;
            background: #36353c;
            color: #b07c4d;
        }

        .cancelbutton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 360px;
            height: 120px;
            border-radius: 10px;
            font-size: 13px;
            background: #36353c;
            color: #8c949f;
        }

        .orderbold {
            font-size: 50px;
        }

        .orderway {
            font-size: 40px;
            padding-left: 10px;
            font-weight: normal;
            font-family: Regular;
        }

        .disable {
            color: #fff !important;
            opacity: 0.5;
            background: #323161;
        }
    }
}

// 付款页面的
.checkoutHeader {
    background: #f8f8f8;
}

.isMinOrderPrice {
    background: #bcbcbc !important;
}

// 筛选弹窗
.fiterDraw::-webkit-scrollbar {
    display: none;
}

.fiterDraw {
    display: flex;
    flex-direction: column;
    font-family: Medium;
    background-color: #282733;
    max-height: 1322px;
    overflow: auto;
    padding: 40px 72px 0px 72px;

    .filter {
        display: flex;
        flex-direction: column;

        .filter-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: right;

            // padding-bottom: 96px;
            .filter-labeld {
                height: 72px;
                font-size: 35px;
                color: #989eb6;
                border: 1px solid #989eb6;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 40px;
                background-color: transparent;
                margin-right: 24px;
                margin-bottom: 24px;
            }
        }
    }

    .clear-filter-box {
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 48px;
        font-family: Medium;
        font-weight: bold;

        // width: 100%;
        // text-align: right;
        .clear-filter {
            color: #b07c4d;
            text-decoration: underline;
            font-size: 35px;
        }

        .title {
            color: #989eb6;
            font-size: 35px;
        }
    }

    .second-clear-filter-box {
        margin-top: 96px;
        padding-top: 40px;
        border-top: 1px solid #cccccc42;
    }

    .subtitle {
        font-size: 35px;
        padding-bottom: 48px;
        color: #bcbcbc;
    }

    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 48px;

        .close {
            position: absolute;
            top: 0;
            right: 0;

            .clearIcon {
                width: 50px;
                height: 50px;
                color: #fff;
            }
        }
    }

    input {
        width: 100%;
        min-height: 120px;
        color: #9791bf;
        border-radius: 50px !important;
        padding: 25px 35px;
        word-break: break-word;
        border-radius: 22px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background-color: #f8f8f8;
    }

    .insertCodebtn {
        width: 100%;
        text-transform: capitalize;
    }

    .dialogName .fontStyle {
        font-size: 50px;
        width: 100%;
        color: #b07c4d;
    }
}

// 菜品特殊标签
.dishTagBox {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    overflow: hidden;

    .specialTag {
        padding: 16px 14px;
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;

        .specialTagStar {
            color: #FFB800;
            margin-right: 8px;
        }

        .specialTagHot {
            color: #FFD233;
            margin-right: 8px;
        }

        .text {
            font-size: 30px;
            color: #fff;
        }
    }

    .specialTagNew {
        background-color: #FF3A3A;
    }

    .specialTagHot {
        background-color: #FF7438;
    }

    .MdFastfood-icon-box {
        width: 70px;
        height: 70px;
        // position: absolute;
        // top: -15px;
        // left: -15px;
        background-color: #ffdc33;
        color: #000;
        // border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .MdFastfood-icon {
            width: 45px;
            height: 45px;
        }
    }
}