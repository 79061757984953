.checkoutOrderDetailPage {
    // padding: 112px 0 150px;
    margin: 112px 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
    .mainPage {
        padding-bottom: 250px;
    }
    // .showedPageItem {
        // height: 100%;
        // overflow-y: scroll;
    // }
    .hasErrorTip {
        padding-bottom: 300px;
    }
}
