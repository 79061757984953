.InsertCodeDialog {
    z-index: 999 !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .MuiDialog-paperWidthSm {
        width: 1104px;
        /* height: 585px; */
        border-radius: 42px;
    }

    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
    }

    .dialogName .close {
        position: absolute;
        top: 0;
        right: 0;
    }

    .insertCode{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #bcbcbc;
        .leftInput{
            width: 48%;
        }
        .rightInput{
            width: 48%;
        }
    }

    .dialogContent {
        display: flex;
        flex-direction: column;
        /* height: 500px; */
    }

    .dialogContent .ok {
        width: 354px;
        height: 144px;
        background-color: #ffdc33;
        color: #000;
        border-radius: 88px;
    }

    .dialogContent .cancel {
        width: 354px;
        height: 144px;
        background-color: #5867b4;
        color: #fff;
        border-radius: 88px;
    }

    input {
        width: 100%;
        min-height: 120px;
        color: #9791bf;
        border-radius: 50px !important;
        padding: 25px 35px;
        word-break: break-word;
        border-radius: 22px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background-color: #f8f8f8;
    }

    .insertCodebtn {
        width: 100%;
        text-transform: capitalize;
    }

    .dialogName .fontStyle {
        font-size: 55px;
        width: 100%;
    }
}
