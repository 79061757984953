.packageDishItem {
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    background-color: #ffffff;
    box-shadow: 5px 5px 1.481vw 0px #eae8ef;
    .menuValore {
        height: 72px;
        background-color: #e8e7eb;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        font-size: 40px;
        font-weight: bold;
        font-family: "Krub";
    }
    .mainInfo {
        padding: 24px 36px;
        position: relative;
        display: flex;
        flex-direction: row;
        height: 250px;
        .dishImg {
            margin-right: 32px;
            width: 200px;
            height: 200px;
            background: #f5f5f5;
            border-radius: 40px;
            position: relative;
            img {
                min-width: 100%;
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 35px;
            }
            .MdFastfood-icon-box {
                width: 70px;
                height: 70px;
                position: absolute;
                top: -22px;
                left: -22px;
                background-color: #ffdc33;
                color: #000;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .MdFastfood-icon {
                    width: 45px;
                    height: 45px;
                }
            }
        }
        .content {
            flex: 1;
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            .clickBox {
                position: absolute;
                width: calc(100% - 324px);
                height: 100%;
                left: 0;
                top: 0;
                z-index: 2;
            }
            .title {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                color: #333333;
                font-size: 40px;
                font-weight: bold;
                text-align: left;
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-word;
                }
                span {
                    color: #ffdc33;
                    margin-bottom: 0;
                }
            }
            .description {
                color: #bcbcbc;
                font-size: 35px;
                text-align: left;
                font-weight: bold;
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-word;
                }
            }
            .dishsNumber {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .left {
                    .price {
                        font-size: 50px;
                        font-family: "Arial";
                        color: #f65252;
                    }
                }
                .right {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .number {
                        // margin: 0 32px;
                        color: #000000;
                        font-weight: bold;
                        font-size: 40px;
                        min-width: 100px;
                        text-align: center;
                    }
                    .is_no_number {
                        color: #bcbcbc;
                    }
                    .MuiIconButton-root {
                        padding: 0;
                        width: 80px;
                        height: 80px;
                        .MuiIconButton-label {
                            margin-bottom: 0;
                        }
                    }
                    .AiFillPlusCircle {
                        width: 80px;
                        height: 80px;
                        color: #ffdc33;
                    }
                    .DisAiFillPlusCircle {
                        width: 80px;
                        height: 80px;
                        color: #f7ebab;
                    }
                    .AiFillMinusCircle {
                        width: 80px;
                        height: 80px;
                        color: #acacac;
                    }
                    .DisAiFillMinusCircle {
                        width: 80px;
                        height: 80px;
                        color: #e8e7eb;
                    }
                }
                .ordina {
                    border-radius: 70px;
                    line-height: inherit;
                    .setMenuBtn {
                        color: #000000de;
                        background-color: #ffdc33;
                        border-radius: 70px;
                        line-height: inherit;
                        padding: 16px 48px;
                        font-size: 40px;
                        font-family: "Krub";
                        font-weight: bold;
                    }
                }
                .disOrdina {
                    .setMenuBtn {
                        background-color: #f7ebab;
                    }
                }
            }
            .allergen-pic {
                width: 58px;
                height: 58px;
                margin: 4px 4px 0 0;
            }
        }
    }
    // .basketMainInfo {
    //     padding: 16px 12px;
    // }
}

.BasketDishItem {
    // height: 248px;
    .mainInfo {
        padding: 16px 36px;
        .content {
            flex: 1;
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            .clickBox {
                position: absolute;
                width: 60%;
                height: 100%;
                left: 0;
                top: 0;
            }
            .title {
                line-height: 72px;
                p {
                    font-size: 35px;
                    font-weight: bold;
                    font-family: "Krub";
                    color: #333333;
                }
                span {
                    color: #333333;
                    margin-bottom: 0;
                }
            }
            .left {
                .price {
                    padding: 0;
                    height: 0;
                    font-size: 35px;
                    font-weight: bold;
                    color: #f65252;
                    font-family: "Krub";
                }
            }
            .dishsNumber {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
.BasketSetDishItem {
    // height: 360px;
    .content {
        flex: 1;
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        .title {
            line-height: 72px;
            p {
                font-size: 40px;
                font-weight: bold;
                color: #333333;
            }
            span {
                color: #333333;
                margin-bottom: 0;
            }
        }
        .left {
            .price {
                padding: 0;
                height: 0;
                font-size: 35px;
                font-weight: bold;
                color: #f65252;
                font-family: "Krub";
            }
        }
        .dishsNumber {
            display: flex;
            justify-content: flex-end;
        }
    }
}
