.secCreateAddress {
    // input:-internal-autofill-selected {
    //     background-color: #3e3c4e !important;
    //     // background-image: none !important;
    //     // -webkit-text-fill-color: #8c949f; // 改变了字体颜色
    //     // box-shadow: inset 0 0 0 1000px #3e3c4c !important;
    // }
    // input:-webkit-autofill {
    //     background-color: #3e3c4e !important;
    //     // background-color: unset !important;
    //     // box-shadow: 0 0 0px 1000px #3e3c4c inset !important;
    //     // -webkit-text-fill-color: #8c949f; // 改变了字体颜色
    // }
    // input:-webkit-autofill:focus {
    //     background-color: #3e3c4e !important;
    //     // background-color: unset !important;
    //     // box-shadow: 0 0 0px 1000px #3e3c4c inset !important;
    //     // -webkit-text-fill-color: #8c949f; // 改变了字体颜色
    // }

    .mainContainer {
        input::-webkit-input-placeholder {
            color: #8c949f !important;
        }
        textarea::-webkit-input-placeholder {
            color: #8c949f !important;
        }
        .addressInput .city::-webkit-input-placeholder {
            color: #8c949f !important;
        }
        background-color: #282733;
        .addressAndNoteHeader {
            color: #fff;
            .closeIco {
                color: #fff;
            }
        }
        .allContainer {
            .googleMapInput {
                label {
                    color: #8c949f;
                }

                .GoogleMapstreetInput {
                    background-color: #3e3c4c;
                    color: #8c949f;
                    border: none;
                }
                textarea {
                    color: #8c949f !important;
                    // &::-webkit-input-placeholder {
                    //     /* WebKit browsers */
                    //     color: #8c949f !important;
                    // }
                }
                .buttonicon {
                    background-color: #3e3c4c;
                    box-shadow: none;
                }
            }
            .addressInput {
                label {
                    color: #8c949f;
                }
                .homeInput {
                    background-color: #3e3c4c;
                    color: #8c949f !important;
                    border: none;
                    // &::-webkit-input-placeholder {
                    //     /* WebKit browsers */
                    //     color: #8c949f;
                    // }
                }
                .zipInput {
                    background-color: #3e3c4c;
                    color: #8c949f !important;
                    border: none;
                    // &::-webkit-input-placeholder {
                    //     /* WebKit browsers */
                    //     color: #8c949f !important;
                    // }
                }
                .city {
                    background-color: #3e3c4c;
                    color: #8c949f !important;
                    border: none;
                    // &::-webkit-input-placeholder {
                    //     /* WebKit browsers */
                    //     color: #8c949f !important;
                    // }
                }
                .lastName {
                    background-color: #3e3c4c;
                    color: #8c949f !important;
                    border: none;
                    // &::-webkit-input-placeholder {
                    //     /* WebKit browsers */
                    //     color: #8c949f !important;
                    // }
                }
            }
            .phoneInput {
                .title {
                    color: #8c949f;
                }
                .mainContainer {
                    background: #3e3c4c;
                    border: none;
                    .MuiButton-label {
                        color: #8c949f;
                    }
                    .phone {
                        background-color: #3e3c4c;
                        color: #8c949f !important;
                        border: none;
                        // &::-webkit-input-placeholder {
                        //     /* WebKit browsers */
                        //     color: #8c949f !important;
                        // }
                    }
                }
            }
            .selectDefault {
                .defaultTip {
                    color: #8c949f;
                }
            }
            div {
                .addressInput {
                    .firstName {
                        background-color: #3e3c4c;
                        color: #8c949f !important;
                        border: none;
                        // &::-webkit-input-placeholder {
                        //     /* WebKit browsers */
                        //     color: #8c949f !important;
                        // }
                    }
                }
            }
        }
        .selectDefault {
            .defaultTip {
                color: #8c949f;
            }
        }
        .accountInfoInvalid{
            color: red;
        }
        div .addAddressBtn {
            background-color: #b07c4d;
            color: #fff;
        }
        .addAddressEditBtn {
            .addAddressEdit {
                background-color: #b07c4d !important;
                color: #fff;
            }
            // .addAddressEdit .cancel {
            //     background-color: #b07c4d !important;
            //     color: #fff;
            // }
        }
    }
    .isNoComplete {
        input::-webkit-input-placeholder {
            color: red !important;
        }
        textarea::-webkit-input-placeholder {
            color: red !important;
        }
    }
}
