.bookingBoxBackgroundVisible {
    visibility: visible;
}
.bookingBoxBackgroundHidden {
    visibility: hidden;
}
.checkoutDrawerMainVisible {
    transform: translate3d(0px, 0px, 0px);
}
.checkoutDrawerMainHidden {
    transform: translate3d(0px, 100%, 0px);
}
.bookingBoxBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000090;
    z-index: 18;
}
.bookingBoxMain {
    flex: 1;
    overflow-y: scroll;

    .required {
        em {
            color: red !important;
        }
        .title {
            color: red !important;
        }
    }
    .bookingDate {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .downIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        svg {
            width: 80px;
            height: 80px;
        }
    }
    .bookingContent {
        font-family: "Krub Medium", "Krub Regular", "Krub";
        margin-top: 70px;
        padding: 0 48px 48px;
        .IconTitle {
            display: flex;
            align-items: center;
            color: #bfbfbf;
            height: 72px;
            margin: 24px 0;
            .icon {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                font-size: 36px;
                margin-left: 24px;
            }
        }
        .boxShadow {
            background-color: #fff;
            border-radius: 20px;
            min-height: 100px;
            padding: 48px 72px;
            text-align: left;
            box-shadow: 0px 10px 15px #9da3c433;
        }
        .chooseTime {
            .flexRow {
                display: flex;
            }
            .timeItem {
                margin-right: 48px;
                color: #989eb6;
                background-color: #f2f2f7;
                padding: 24px 48px;
                border-radius: 50px;
                font-size: 40px;
                line-height: 40px;
                margin-right: 24px;
                margin-bottom: 36px;
                cursor: pointer;
            }
            .activeBg {
                background-color: #ffdc33;
                color: #000;
            }
            .disabledBg {
                background-color: transparent;
            }
            .name {
                font-size: 40px;
                color: #9791bf;
                margin-bottom: 36px;
            }
        }
        .chooseReachTime {
            .reachTimeBox {
                border-radius: 100px;
                border: 1px solid #bfbfbf;
                height: 120px;
                color: #989eb6;
                font-size: 45px;
                display: flex;
                align-items: center;
                padding-left: 36px;
            }
        }
        .chooseNumberOfPeople {
            display: flex;
            align-items: center;
            justify-content: center;
            .button {
                width: 100px;
                height: 100px;
                background-color: #f2f2f7;
                border-radius: 20px;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 50%;
                    height: 50%;
                }
            }
            .peopleNumber {
                margin: 0 72px;
                font-size: 45px;
                color: #989eb6;
            }
        }
        .details {
            .title {
                font-size: 40px;
                color: #9791bf;
                margin-top: 48px;
                margin-bottom: 48px;
                em {
                    color: #9791bf;
                }
            }
            .radiusInputBox {
                position: relative;
                border-radius: 88px;
                border: 1px solid #cfcfcf;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 144px;
                border-radius: 88px;
                width: 100%;
                .radiusInput {
                    background: #fff;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    height: 100%;
                    color: #394474;
                    font-size: 4.63vw;
                    width: 100%;
                    padding: 0 50px;
                    border-radius: 88px;
                }
            }
            .phoneInput {
                position: relative;
                .phone-main {
                    svg {
                        right: 50px;
                    }
                    .MuiButton-label {
                        color: #989eb6;
                    }
                    .phone-insert-in {
                        width: 600px;
                        background: #fff;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        height: 100%;
                        color: #394474;
                        font-size: 50px;
                        border-radius: 88px;
                        padding-left: 20px;
                    }
                }
            }
            .areaBox {
                height: 500px;
                border-radius: 30px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: center;
                .area {
                    box-sizing: border-box;
                    font-family: "Krub";
                    font-size: 40px;
                    color: #989eb6;
                    height: 500px;
                    border: none;
                    border-radius: 30px;
                    padding: 48px 48px;
                    width: 100%;
                    &::-webkit-input-placeholder {
                        font-family: "Krub";
                        font-weight: 400;
                        font-style: normal;
                        font-size: 40px;
                        color: #989eb6;
                    }
                }
            }
            .bubblesBox {
                position: absolute;
                top: 0;
                display: flex;
                width: 100%;
                height: 1px;
                justify-content: center;
                align-items: flex-end;
                z-index: 1;
            }
            .airBox {
                position: unset;
            }
            .confirmBox {
                position: relative;
                display: flex;
                margin: 48px 0;
                .confirm {
                    flex: 1;
                    height: 144px;
                    border-radius: 88px;
                    background-color: #ffdc33;
                    font-size: 50px;
                    font-family: "Arial Normal", "Arial";
                    font-weight: 400;
                }
            }
        }
        .noOrderTime {
            height: 100px;
            font-size: 40px;
            color: #989eb6;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .disabled {
        background-color: #cecece !important;
    }
    .project-logo {
        width: 70px;
        height: 70px;
    }
    .provided-box {
        font-size: 32px;
        background-color: #f8f8f8;
        padding-bottom: 48px;
        border-bottom: 1px solid #cecece99;
        .provided-text-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .project-logo-box {
            margin-right: 12px;
        }
        .text {
            font-size: 32px;
            color: #989eb6;
        }
        .clickPolicyText {
            font-size: 32px;
            color: #5867b4;
        }
    }
}
