.checkoutCouponListComponent {
    -moz-user-select: none; /* 火狐 */
    -webkit-user-select: none; /* 谷歌 */
    -ms-user-select: none; /* IE */
    user-select: none;
    .allCouponsBox {
        .couponItemBox {
            .couponItem {
                transition: all cubic-bezier(0, 0, 0, 1) 0.35s;
            }
            .ruleText {
                width: 80%;
            }
        }
        .showCantUseText {
            .couponItem {
                margin: 100px auto 30px;
            }
        }
        .couponItemBubbless {
            top: -100px;
            background-color: #ffdc33;
            span {
                color: #000;
            }
            &::after {
                border-top: 24px solid #ffdc33;
            }
        }
    }
    .notfind {
        font-size: 35px;
    }
}
