.QRCodeDialog {
    .MuiDialogContent-root:first-child {
        padding: 24px;
    }
    .MuiPaper-root {
        background-color: transparent;
    }
    .MuiPaper-elevation24 {
        box-shadow: none;
    }
    .closeIconBox {
        text-align: center;
        margin-bottom: 72px;
        .clearIcon {
            color: #fff;
            background-color: #ffffff9a;
            border-radius: 50%;
            padding: 12px;
        }
    }
    .QRcodeBox {
        background-color: #fff;
        padding: 48px;
    }
}
