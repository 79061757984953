.reservations-page {
    .notfind {
        width: 100vw;
        height: calc(100vh - 196px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-family: Medium;
    }
}
