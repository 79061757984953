.paymentMethod {
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: "kurb";
    .grayBackground {
        background-color: #f8f8f8;
    }
    .choosePaymentMethodBox {
        overflow: auto;
        height: calc(100% - 196px - 260px);
    }
    .choosePaymentMethodText {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 24px 72px;
        font-size: 40px;
        font-weight: bold;
        color: #707070;
    }
    .noPaymentMethodSet {
        color: #5867b4;
        font-size: 40px;
        text-align: left;
        padding-left: 72px;
    }
    .paymentMethodList {
        padding: 24px 0;
        img {
            width: 250px;
        }
        .VivawalletLogo {
            width: 450px;
        }
        .paymentLabel {
            height: 100px;
            margin: 24px 72px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px;
            background-color: #f2f2f2;
            box-shadow: 1px 3px 10px 1px #eae9ef;
            border-radius: 40px;
            position: relative;
            .content {
                display: flex;
                align-items: center;
                justify-content: center;
                .cashText {
                    font-size: 45px;
                    color: #5867b4;
                    .maxOfflineFee {
                        display: block;
                        font-size: 35px;
                        color: #7a86c4;
                    }
                }
            }
            .selectedBox {
                width: 150px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .circleBox {
                    border: 3px solid #989eb6;
                    border-radius: 150px;
                    background-color: #fff;
                    width: 55px;
                    height: 55px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .selectedCircleBox {
                    width: 58px;
                    height: 58px;
                    background-color: #ffdc33;
                    border: 0px solid #989eb6;
                    svg {
                        width: 80%;
                        height: 80%;
                        color: #000;
                    }
                }
                .disabledCircleBox {
                    width: 58px;
                    height: 58px;
                    border: 0px solid #989eb6;
                    svg {
                        width: 100%;
                        height: 100%;
                        color: #000;
                    }
                }
            }
        }
        .allUnselected {
            background-color: #fff;
        }
    }
    .twoPaynowList {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 24px 72px;
        .paymentLabel {
            height: 50px;
            margin: 0 24px 0 0;
        }
        .content {
            img {
                width: 200px;
            }
            .cardLogo {
                width: 150px;
            }
            .posLoge {
                width: 50px;
            }
            .cashLogo {
                width: 75px;
                height: 75px;
            }
            .cardLogoText {
                margin-left: 24px;
                font-size: 30px;
                span {
                    display: block;
                    white-space: nowrap;
                }
            }
        }
    }
    .createOrderBtnBox {
        position: absolute;
        bottom: 100px;
        left: 0;
        right: 0;
        width: 100%;
        .createOrderBtn {
            height: 144px;
            border-radius: 88px;
            background-color: #ffdc33 !important;
            color: #22223a;
            width: calc(100% - 144px);
        }
        .disabledCreateOrderBtn {
            background-color: #e0dcdc !important;
        }
    }
    .paidSuccessView {
        display: flex;
        height: calc(100% - 197px);
        background-color: #ffec33;
        .paidSuccessViewContent {
            width: 50%;
            margin: 25% auto 0;
            .paidText {
                font-size: 50px;
                color: #784122;
                font-weight: bold;
                margin-bottom: 48px;
            }
            .paidSuccessImg {
                width: 550px;
                height: 500px;
            }
        }
        .myOrderBtn {
            margin-top: 96px;
            width: 100%;
            background-color: #5867b4;
            color: #fff;
            border-radius: 200px;
        }
    }
}
// 选择付款方式页面的弹窗
.paymentMethodDialog {
    .MuiDialog-paper {
        border-radius: 40px;
    }
    .paymentMethodDialogTitle {
        text-align: right;
        position: absolute;
        right: 0;
        padding: 48px;
        z-index: 1;
    }
    .paymentMethodDialogContent {
        text-align: center;
        min-height: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 48px;
        .tipsText {
            font-family: "Krub SemiBold", "Krub Regular", "Krub";
            font-style: normal;
        }
        .errorIco {
            width: 100px;
            height: 100px;
            color: #fc432d;
        }
        p {
            margin-top: 60px;
            font-size: 46px;
            color: #989eb6;
            font-weight: bold;
        }
        a {
            font-size: 60px;
            color: #3d3c69;
            margin-top: 50px;
        }
    }
}
// 付款方式气泡提示
.paymentLabelAirBubbles {
    top: -50px;
}
