.getCuoponBoxBackgorund {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #00000080;

    .getCuoponBox {
        border-width: 0px;
        position: absolute;
        right: 50px;
        top: 196px;
        width: 350px;
        background: inherit;
        background-color: #fff;
        color: #000;
        border: none;
        border-radius: 10px;
        font-family: "kurb Regular";
        font-size: 35px;
        user-select: none;
        .cuoponBoxRow {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 120px;
            background-color: #fff;
            border-radius: 10px;
            .MuiButton-text {
                padding: 0;
            }
            .icon {
                padding: 0 24px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: boder-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -o-box-sizing: border-box;
                -ms-box-sizing: border-box;
                font-weight: normal;
            }
            .cuoponImg {
                width: 100%;
            }
        }
        .cuoponBoxRow:active {
            background-color: #5867b4;
            color: #fff;
            .cuoponImg {
                filter: invert(100%);
            }
        }
        &:active {
            background-color: #5867b4;
        }
        &:active::after {
            border-bottom: 23px solid #5867b4;
        }
        &::after {
            position: absolute;
            top: -20px;
            right: 20px;
            content: "";
            border-left: 23px solid transparent;
            border-right: 23px solid transparent;
            border-bottom: 23px solid #fff;
        }
        span {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            text-align: center;
        }
    }
}
