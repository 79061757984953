.checkoutBackgroundVisible {
    visibility: visible;
}

.checkoutBackgroundHidden {
    visibility: hidden;
}

.checkoutDrawerMainVisible {
    transform: translate3d(0px, 0px, 0px);
}

.checkoutDrawerMainHidden {
    transform: translate3d(0px, 100%, 0px);
}

.checkoutBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000090;
    z-index: 18;
}

.checkoutDrawerMain {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f8f8f8;
    z-index: 18;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    transition: all cubic-bezier(0, 0, 0, 1) 0.35s;
    padding: 48px 48px 0;
    font-family: "kurd";
    display: flex;
    flex-direction: column;
    -moz-user-select: none;
    /* 火狐 */
    -webkit-user-select: none;
    /* 谷歌 */
    -ms-user-select: none;
    /* IE */
    user-select: none;
    // 处理 ios 点击有阴影
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    .headBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        padding: 0 48px;
        top: 0;
        left: 0;
        right: 0;
        height: 150px;
        background-color: #f8f8f8;
        border-top-left-radius: 80px;
        border-top-right-radius: 80px;
        z-index: 2;

        .actionIcon {
            width: 80px;
            height: 80px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .title {
            flex: 1;
            font-size: 50px;
            font-weight: bold;
            margin-right: 80px;
        }
    }
}

.bookingMain {
    padding-top: 0;

    .checkout-sign-in-phone {
        padding-top: 160px;
    }

    .complete-info-component {
        .info-top-bar {
            display: flex;
            align-items: center;
            height: 150px;

            .complete-title {
                margin-right: 80px;
            }
        }

        .tip-text {
            font-size: 35px;
            color: #bcbcbc;
            text-align: left;
            word-break: break-word;
        }
    }
}

.bookingMainPageBG {
    top: 196px;
}

.bookingMainPage {
    top: 0;
    border-radius: 0;

    .downIcon {
        display: none !important;
    }

    .bookingContent {
        margin-top: 0 !important;
    }
}

.input-component {
    width: calc(100% - 120px);
    padding: 0 60px;
    border-radius: 88px;
    height: 144px;
    border: none;
    outline: none;
    font-size: 45px;
    color: #8f97c1;
}

input::-webkit-input-placeholder {
    color: #8f97c1;
}

input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #8f97c1;
}

input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #8f97c1;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #8f97c1;
}

.checkout-add-address {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;

    .text {
        padding-left: 10px;
        font-size: 40px;
        margin-top: 48px;
    }

    .tipsText {
        text-align: left;
        padding: 0 30px;
        margin-top: 30px;
        color: #fd7b88;
        font-weight: bold;
        font-size: 40px;
    }

    .info-area {
        display: flex;
        flex-direction: column;

        .text {
            padding-left: 10px;
            font-size: 40px;
            margin: 48px 0;
        }
    }

    .detail-info-area {
        display: flex;
        flex-direction: column;

        .text {
            padding-left: 10px;
            font-size: 40px;
            margin: 48px 0;
        }

        .detail-input {
            width: calc(100% - 120px);
            padding: 0 60px;
            border-radius: 88px;
            height: 144px;
            border: none;
            outline: none;
            font-size: 45px;
            color: #8f97c1;
        }

        input::-webkit-input-placeholder {
            color: #8f97c1 !important;
        }

        textarea::-webkit-input-placeholder {
            color: #8f97c1 !important;
        }
    }

    .complete-google-map {
        height: 144px;
        width: 100%;
        border-radius: 88px;
        margin: 48px auto 0 auto;

        .SearchBox {
            width: 100%;
        }

        .SearchBoxinput {
            box-sizing: border-box;
            border: 1px solid transparent;
            height: 144px;
            background-color: #fff;
            border: none;
            border-radius: 88px;
            padding: 0 60px;
            outline: none;
            font-size: 40px;
            color: #5867b4;
            width: 100%;
        }
    }

    .not-address {
        .SearchBoxinput {
            color: red !important;
        }
    }

    .selectDefault {
        display: flex;
        align-items: center;
        margin: 50px 0;

        .optionIco {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            background: inherit;
            background: rgba(255, 255, 255, 1);
            box-sizing: border-box;
            border: 3px solid rgba(152, 158, 182, 1);
            border-radius: 15px;
        }

        .defaultTip {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #6c6c6c;
            margin-left: 24px;
        }

        .accountInfoInvalid {
            color: red;
        }
    }

    .complace-btn-active {
        border-radius: 88px;
        height: 144px;
        text-transform: unset;
        margin: 144px 50px;
        background-color: #ffde33;
    }

    .not-active {
        background-color: #bcbcbc;
    }
}

.isNoContent {
    input::-webkit-input-placeholder {
        color: red !important;
    }

    textarea::-webkit-input-placeholder {
        color: red !important;
    }
}

.signUpPhoneInput {

    // background-color: #b4b5b580;
    .phone {
        background-color: transparent;
    }
}

.loadingView {
    position: absolute;
    background: #00000020;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
}