.checkoutOrderTableware {
    .close {
        color: #cecece !important;
        width: 72px !important;
        height: 72px !important;
        margin: 0 8px;
    }

    .check {
        color: #ffdc33 !important;
        width: 72px !important;
        height: 72px !important;
        margin: 0 8px;
    }

    .priceNumber {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .number {
            margin: 0 32px;
            font-size: 35px;
            font-weight: bold;
            color: #000;
        }

        .iconBtn {
            padding: 0;
            width: 90px;
            height: 90px;

            .minusBtn {
                width: 100%;
                height: 100%;
                color: #acacac;
            }

            .plusBtn {
                width: 100%;
                height: 100%;
                color: #ffdc33;
            }
        }

        .disabledIconBtn {
            svg {
                color: #e8e7eb !important;
            }
        }
    }
}