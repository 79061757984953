.blackGoldMainPaymentBox {
    box-sizing: border-box;
    min-height: 840px;
    max-height: 100%;
    position: fixed;
    left: 0px;
    bottom: 0px;
    right: 0;
    background-color: #36353c;
    border-top-right-radius: 70px;
    border-top-left-radius: 70px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    .main {
        background-color: #282733;
        padding: 64px 72px 72px;
    }
    .arrowBottomIco {
        position: absolute;
        left: calc(50% - 40px);
        font-size: 80px;
        color: #bcbcbc;
    }
    .orderMoreBox {
        padding: 48px 72px;
        font-family: "Krub", "Krub";
        font-style: normal;
        font-size: 40px;
        color: #b5b4b4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .orderMore {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: underline;
        }
        .isAddNoted {
            text-decoration: underline;
        }
    }
    .viewCouponsBox {
        margin: 48px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .viewCoupons {
            font-family: "Krub", "Krub";
            font-style: normal;
            font-size: 40px;
            color: #b5b4b4;
        }
    }
    .paymentMethod {
        margin: 48px 0;
    }
    .coupontitle {
        font-family: "Krub Bold", "Krub";
        font-weight: 700;
        font-style: normal;
        font-size: 40px;
        color: #caa376;
    }
    .paymentCouponPackage {
        width: 106%;
        display: flex;
        align-items: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        // margin: 0 24px;
        .blackGoldCouponItem {
            margin: 0 24px;
            &:nth-of-type(1) {
                margin-left: 0;
            }
        }
        .ruleText {
            width: 80%;
        }
        .noCouponsText {
            color: #b5b4b4;
            font-size: 30px;
        }
    }
    .paymentMethod {
        text-align: left;
    }
    .paypalButton {
        position: relative;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 106%;
        .workBubble {
            position: absolute;
            top: -80px;
            left: 20px;
            padding: 20px 30px;
            min-width: 300px;
            background: inherit;
            background-color: #323161;
            border: none;
            border-radius: 100px;
            &::after {
                position: absolute;
                bottom: -23px;
                right: calc(50% - 20px);
                content: "";
                border-left: 24px solid transparent;
                border-right: 24px solid transparent;
                border-top: 24px solid #323161;
            }
            span {
                color: #ffffff;
                font-size: 30px;
                line-height: 48px;
                font-weight: 400 !important;
            }
        }
        .last {
            .paymentWayBtn {
                font-size: 40px;
                margin-right: 0;
                flex-direction: column;
            }
        }
        .paymentWayBtn {
            width: 350px;
            height: 125px;
            color: #5867b4;
            border: 1px solid #b07c4d;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            font-size: 30px;
            span {
                display: block;
                color: #9791bf;
                font-size: 30px;
            }
            svg {
                margin-right: 10px;
            }
        }
        // .paypalBtn {
        //     background-image: url("../../../static/payment-images/PayPal_logo.png");
        //     background-size: 85%;
        // }
        // .stripeBtn {
        //     background-image: url("../../../static/payment-images/stripe_logo.png");
        // }
        // .payNowBtn {
        //     background-image: url("../../../static/payment-images/2paynow_logo.png");
        // }
    }
    .twoPayNowWays {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 106%;
        margin-top: 72px;
        div {
            flex: 1;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            height: 125px;
            color: #5867b4;
            border: 1px solid #5867b4;
            border-radius: 30px;
            margin: 0 6px;
            background-color: #fff;
        }
        div:nth-last-of-type(1) {
            margin-right: 0;
        }
        div:nth-of-type(1) {
            margin-left: 0;
        }
        // .aliPay {
        //     background-image: url("../../../static/payment-images/zhifubao_logo.png");
        // }
        // .weChat {
        //     background-image: url("../../../static/payment-images/weixin_logo.png");
        // }
        // .bankCard {
        //     background-image: url("../../../static/payment-images/yinhangka_logo.png");
        //     display: flex;
        //     align-items: flex-end;
        //     flex-direction: column;
        //     justify-content: center;
        //     color: #333;
        //     span {
        //         width: 60%;
        //         font-size: 30px;
        //     }
        // }
    }
    .couponbox {
        display: flex;
        align-items: center;
        padding: 48px 0;
        border-bottom: 2px solid #989eb6;

        .doneicon {
            width: 48px;
            height: 48px;
            border-radius: 48px;
            font-weight: bold;
            color: #ffdc33;
            background: #3d3c69;
        }
        .undoicon {
            color: #fff;
            background: #fff;
            border: 1px solid #3d3c69;
        }
        .couponRow {
            border-bottom: 0px solid;
            padding: 5px 0;
        }
        .generalRow {
            display: flex;
            align-items: center;
            .placeholderBox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 48px;
            }
            p {
                font-size: 40px;
                font-weight: bold;
                font-family: "Krub";
                font-weight: 400;
                font-style: normal;
                color: #858585;
                margin-left: 24px;
            }
            .atLeast {
                font-size: 30px;
                font-family: "Krub";
                font-weight: 400;
                font-style: normal;
                color: #858585;
                padding-left: 10px;
            }
        }
    }
    .couponText {
        color: #b5b4b4 !important;
        font-size: 55px;
        font-family: "kurb Regular";
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0;
        .title {
            font-family: "Krub Bold", "Krub";
            font-weight: 700;
            font-style: normal;
            font-size: 40px;
            color: #b5b4b4;
        }
        .delLine {
            text-decoration: line-through; //删除线
        }
        .bold {
            font-weight: bold;
        }
        .fontSize48 {
            font-size: 48px;
        }
        .couponIcon {
            width: 48px;
            height: 48px;
            color: #b5b4b4 !important;
            margin: 0 24px;
            display: flex;
            align-content: center;
        }
        .itemCenter {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 48px;
        }
    }
    .totalPrice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 48px 0 33px;

        .title {
            font-family: "Krub Bold", "Krub";
            font-weight: 700;
            font-style: normal;
            font-size: 40px;
            color: #caa376;
        }
        .price {
            font-family: "Krub Bold", "Krub";
            font-weight: 700;
            font-style: normal;
            font-size: 55px;
            color: #caa376;
        }
    }
    .shippingFee {
        display: flex;
        justify-content: flex-end;
        span {
            font-family: "Krub Medium", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 40px;
            text-align: right;
            color: #bcc0cf;
            &:nth-of-type(2) {
                color: #000;
                padding-left: 10px;
            }
        }
    }
    .footerBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
        color: #333;
        .checkoutBtn {
            width: 450px;
            height: 144px;
            background: inherit;
            background-color: #b07c4d;
            color: #fff;
            border: none;
            border-radius: 50px;
            font-size: 50px;
            font-weight: 500;
        }
        .forbidPaymentBtn {
            background-color: #bcbcbc;
        }
    }
}
