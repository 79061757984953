.fiscal-information-main {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    background-color: #f8f8f8;
    z-index: 1;
}

.fiscal-information-content {
    width: 960px;
    margin: auto;
    margin-top: 72px;
    padding: 0 6px;
    height: calc(100% - 196px);
    .main-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 75vh;
        padding-bottom: 50px;
        align-items: center;
        overflow: auto;
        .account-tit {
            color: #9791bf;
            font-weight: 600;
            font-style: normal;
            font-size: 3.704vw;
        }
        .account-msg {
            font-size: 40px;
            color: #9791bf;
        }
        .edit-input {
            width: 792px;
            text-align: left;
        }
        .editInput {
            background-color: #fff;
            border-style: none;
        }
    }
}
